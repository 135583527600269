import {
  Box,
  Collapse,
  FormControl,
  Grid,
  InputAdornment,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import { BackButton, Button, Heading, Input } from "../../../components/Common";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
} from "@mui/material";
import { MdSearch } from "react-icons/md";
import { VscFilter } from "react-icons/vsc";

const referralAgenciesData = [
  {
    id: 1,
    name: "Agency One",
    location: "New York, USA",
    contact: "123-456-7890",
    totalReferrals: 50,
  },
  {
    id: 2,
    name: "Agency Two",
    location: "Los Angeles, USA",
    contact: "098-765-4321",
    totalReferrals: 30,
  },
  {
    id: 3,
    name: "Agency Three",
    location: "London, UK",
    contact: "987-654-3210",
    totalReferrals: 70,
  },
  {
    id: 4,
    name: "Agency Four",
    location: "Toronto, Canada",
    contact: "654-321-0987",
    totalReferrals: 25,
  },
  {
    id: 5,
    name: "Agency Five",
    location: "Sydney, Australia",
    contact: "321-098-7654",
    totalReferrals: 100,
  },
];

const Total_Referrals = () => {
  const clearFilter = () => {
    setShowFilter(!showFilter);
  };
  const [showFilter, setShowFilter] = useState(false);
  const handleFilterClick = () => {
    setShowFilter(!showFilter);
  };
  return (
    <>
      <Stack gap={2}>
        <Stack>
          <Box display={"flex"} gap={1}>
            <BackButton />
            <Heading head={"Total Referrals"} />
          </Box>
        </Stack>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            marginBottom: "16px",
            flexWrap: "wrap",
            width: "100%",
            gap: 1,
          }}
        >
          <FormControl sx={{ width: { xs: "100%", md: "500px" } }}>
            <Typography>Search </Typography>
            <TextField
              placeholder="Search"
              name="location"
              // value={filter}
              // onChange={(e) => setFilter(e.target.value)}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <MdSearch />
                  </InputAdornment>
                ),
              }}
              fullWidth
            />
          </FormControl>
          <Stack flexDirection={"row"} alignItems={"center"} gap={1}>
            
            <Button
              buttonName={<VscFilter />}
              style={{
                padding: "10px 11px",
                backgroundColor: "secondary.main",
              }}
              color="white"
              size="small"
              onClick={handleFilterClick}

              variant="contained"
              sx={{ mt: 2 }}
            />
          </Stack>
        
        </Box>
        <Collapse Collapse in={showFilter}>
          <Stack
            mb={"12px"}
            sx={{ backgroundColor: "white.main" }}
            flexDirection={"row "}
            gap={2}
            flexWrap={"wrap"}
            justifyContent={"flex-end"}
            p={1.5}
            alignItems={"center"}
          >
            <Grid className="filterinput">
              <Typography className="inputfont">{"Agency ID"}</Typography>
              <Input placeholder={"Search Agency ID"} type="text" />
            </Grid>
            <Grid className="filterinput">
              <Typography className="inputfont">{"Agency Name"}</Typography>
              <Input placeholder={"Search Agency Name"} type="text" />
            </Grid>

            <Grid className="filterinput">
              <Typography className="inputfont">{"Location"}</Typography>
              <Input placeholder={"Search Location"} type="text" />
            </Grid>
           
          
            <Stack gap={1} flexDirection={"row"}>
              <Box>
                <Button buttonName={"Filter"} />
              </Box>
              <Box>
                <Button buttonName={"Clear"}  color='tertiary' onClick={() => clearFilter()} />
              </Box>
            </Stack>
          </Stack>
        </Collapse>
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Agency ID</TableCell>
                <TableCell>Agency Name</TableCell>
                <TableCell>Location</TableCell>
                <TableCell>Contact</TableCell>
                <TableCell>Total Referrals</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {referralAgenciesData.map((agency) => (
                <TableRow key={agency.id}>
                  <TableCell>{agency.id}</TableCell>
                  <TableCell>{agency.name}</TableCell>
                  <TableCell>{agency.location}</TableCell>
                  <TableCell>{agency.contact}</TableCell>
                  <TableCell>{agency.totalReferrals}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Stack>
    </>
  );
};

export default Total_Referrals;
