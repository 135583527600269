import React, { useState } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Stack,
  InputAdornment,
  TextField,
  Typography,
  FormControl,
  Box,
  IconButton,
  Tooltip,
  Collapse,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  Card,
  CardContent,
} from "@mui/material";
import { Button, Heading, Input } from "../../components/Common";
import { MdEdit, MdSearch } from "react-icons/md";
import { VscFilter } from "react-icons/vsc";
import { MdArrowDropDown, MdArrowDropUp } from "react-icons/md";
import { Route } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { IoMdEye } from "react-icons/io";
import { CloseIcon } from "../../helper/Icons";

const initialWaitlistData = [
  {
    id: 1,
    serviceType: "ABA Therapy",
    agency: "HealthCare Agency",
    position: 3,
    notes: "Awaiting confirmation of slot availability.",
    timeslot: "01:00 PM to 04:00 PM",
  },
  {
    id: 2,
    serviceType: "OT Services",
    agency: "SpeechPath Clinic",
    position: 1,
    notes: "Urgent case. High priority.",
    timeslot: "02:00 PM to 05:00 PM",
  },
  {
    id: 3,
    serviceType: "Case Management",
    agency: "Recovery Center",
    position: 2,
    notes: "Requires additional information.",
    timeslot: "03:00 PM to 06:00 PM",
  },
  {
    id: 4,
    serviceType: "SLP Services",
    agency: "HealthCare Agency",
    position: 3,
    notes: "Awaiting confirmation of slot availability.",
    timeslot: "04:00 PM to 07:00 PM",
  },
];

const Waitlist_Status = () => {
  const [waitlistData, setWaitlistData] = useState(initialWaitlistData);
  const [sortDirection, setSortDirection] = useState("asc");
  const [sortColumn, setSortColumn] = useState("serviceType");

  const handleSort = (column) => {
    const isAscending = sortColumn === column && sortDirection === "asc";
    const sortedData = [...waitlistData].sort((a, b) => {
      if (a[column] < b[column]) return isAscending ? 1 : -1;
      if (a[column] > b[column]) return isAscending ? -1 : 1;
      return 0;
    });
    setSortDirection(isAscending ? "desc" : "asc");
    setSortColumn(column);
    setWaitlistData(sortedData);
  };
  const [showFilter, setShowFilter] = useState(false);
  const handleFilterClick = () => {
    setShowFilter(!showFilter);
  };

  const navigate = useNavigate();
  const [open, setOpen] = useState(false);

  const serviceData = {
    serviceName: "Consultation Service",
    agencyName: "Health Agency",
    position: "Doctor",
    timeSlot: "10:00 AM - 12:00 PM",
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  return (
    <>
      <Stack spacing={2}>
        <Heading head={"Waitlist Status"} />
        <Box
          sx={{
            marginBottom: 4,
            display: "flex",
            flexDirection: { xs: "column", sm: "row" },
            justifyContent: "space-between",
            alignItems: "end",
            gap: 2,
          }}
        >
          <FormControl sx={{ width: { xs: "100%", md: "500px" } }}>
            <Typography>Search</Typography>
            <TextField
              placeholder="Search"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <MdSearch />
                  </InputAdornment>
                ),
              }}
              fullWidth
            />
          </FormControl>

          {/* Service Category and Availability Filters */}
          <Stack
            sx={{
              display: "flex",
              flexDirection: "row",
              gap: 2,
              justifyContent: { xs: "start", sm: "flex-end" },
            }}
          >
            <Button
              buttonName={"Create Service"}
              color="primary"
              variant="contained"
              sx={{ height: "fit-content" }}
              onClick={() => {
                navigate("/create-service");
              }}
            />
            <Button
              buttonName={<VscFilter />}
              color="white"
              variant="contained"
              sx={{ height: "fit-content" }}
              onClick={handleFilterClick}
            />
          </Stack>
        </Box>
        <Collapse in={showFilter}>
          <Stack
            sx={{ backgroundColor: "white.main" }}
            flexDirection={"row "}
            gap={2}
            flexWrap={"wrap"}
            justifyContent={"flex-end"}
            p={1.5}
            alignItems={"center"}
          >
            <Grid className="filterinput">
              <Input
                placeholder={"Service Type"}
                type="text"
                labelinput="Service Type"
              />
            </Grid>

            <Grid className="filterinput">
              <Input
                placeholder={"Search Position"}
                type="number"
                labelinput="Position"
              />
            </Grid>

            <Grid className="filterinput">
              <Input
                placeholder={"Search Agency"}
                type="number"
                labelinput="Agency"
              />
            </Grid>

            <Stack gap={1} flexDirection={"row"}>
              <Box>
                <Button color="primary" buttonName={"Filter"} />
              </Box>
              <Box>
                <Button
                  buttonName={"Clear"}
                  color="tertiary"
                  onClick={() => setShowFilter(false)}
                />
              </Box>
            </Stack>
          </Stack>
        </Collapse>

        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell onClick={() => handleSort("serviceType")}>
                  Service Type
                  {sortColumn === "serviceType" && sortDirection === "asc" ? (
                    <MdArrowDropUp />
                  ) : (
                    <MdArrowDropDown />
                  )}
                </TableCell>
                <TableCell onClick={() => handleSort("position")}>
                  Position
                  {sortColumn === "position" && sortDirection === "asc" ? (
                    <MdArrowDropUp />
                  ) : (
                    <MdArrowDropDown />
                  )}
                </TableCell>
                <TableCell onClick={() => handleSort("agency")}>
                  Agency
                  {sortColumn === "agency" && sortDirection === "asc" ? (
                    <MdArrowDropUp />
                  ) : (
                    <MdArrowDropDown />
                  )}
                </TableCell>
                {/* <TableCell>Time Slot </TableCell> */}

                <TableCell>Action</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {waitlistData.map((row) => (
                <TableRow key={row.id} onClick={handleClickOpen}>
                  <TableCell>{row.serviceType}</TableCell>
                  <TableCell>{row.position}</TableCell>
                  <TableCell>{row.agency}</TableCell>
                  {/* <TableCell>{row.timeslot}</TableCell> */}
                  <TableCell>
                    <Box gap={1} display={"flex"} justifyContent={"flex-end"}>
                      <Tooltip title="Edit">
                        <IconButton
                          sx={{
                            backgroundColor: "tertiary.main",
                            color: "white.main",
                          }}
                          onClick={() => {
                            navigate("/view-waitlist-status");
                          }}
                        >
                          <MdEdit size={15} />
                        </IconButton>
                      </Tooltip>
                      <Tooltip title="View">
                        <IconButton
                          sx={{
                            backgroundColor: "primary.main",
                            color: "white.main",
                          }}
                          onClick={handleClickOpen}
                          // onClick={() => {
                          //   navigate("/view-waitlist-status");
                          // }}
                        >
                          <IoMdEye size={15} />
                        </IconButton>
                      </Tooltip>
                    </Box>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Stack>

      <Dialog open={open} onClose={handleClose} maxWidth="sm" fullWidth>
        <DialogTitle sx={{ m: 0, p: 2 }} id="Common_modal">
          Waitlist Status
        </DialogTitle>
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
        <DialogContent>
          <Grid container spacing={2} maxWidth={{ sm: "600px" }}>
            <Grid item xs={12} sm={6}>
              <Typography variant="subtitle1">Service Type:</Typography>
              <Typography variant="body2">ABA Therapy</Typography>
            </Grid>

            <Grid item xs={12} sm={6}>
              <Typography variant="subtitle1">Agency:</Typography>
              <Typography variant="body2">HealthCare Agency</Typography>
            </Grid>

            <Grid item xs={12} sm={6}>
              <Typography variant="subtitle1">Position:</Typography>
              <Typography variant="body2">3</Typography>
            </Grid>
          </Grid>
          <Grid container>
            <Grid item xs={12} mt={2}>
            <Typography variant="subtitle1">Time Slot:</Typography>
            </Grid>
            <Grid xs={6} sm={3}>

              <Typography variant="body1" mt={1}>
                <strong>Day:</strong> Tuesday
              </Typography>
              <Typography variant="body1">
                <strong>From:</strong> 3:00 PM
              </Typography>
              <Typography variant="body1">
                <strong>To:</strong> 5:00 PM
              </Typography>
            </Grid>
            <Grid xs={6} sm={3}>
              <Typography variant="body1" mt={1}>
                <strong>Day:</strong> Wednesday
              </Typography>
              <Typography variant="body1">
                <strong>From:</strong> 9:00 AM
              </Typography>
              <Typography variant="body1">
                <strong>To:</strong> 5:00 PM
              </Typography>
            </Grid>
            <Grid xs={6} sm={3}>
              <Typography variant="body1" mt={1}>
                <strong>Day:</strong> Thrusday
              </Typography>
              <Typography variant="body1">
                <strong>From:</strong> 10:00 AM
              </Typography>
              <Typography variant="body1">
                <strong>To:</strong> 01:00 PM
              </Typography>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="tertiary" buttonName={"Close"} />
        </DialogActions>
      </Dialog>
    </>
  );
};

export default Waitlist_Status;
