import {
  Box,
  FormControl,
  IconButton,
  InputAdornment,
  Paper,
  Stack,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { MdDelete, MdEdit, MdSearch } from "react-icons/md";
import { Button, Heading, Modal } from "../../components/Common";
import { planDelete, planList, planStatusChange } from "../../Service/Plan.service";
import { Pagination } from "../../components/Common/Table";
import TableRowsLoader from "../../components/Loader/Skeleton";
import { notificationSuccess } from "../../state/Action/NotificationAction";
import { useDispatch } from "react-redux";

const Plan = () => {
  const [isModalOpen, setIsModalOpen] = useState({
    open: false,
    currentComponent: "",
    para: "",
    head: "",
  });
  const [search, setSearch] = useState("");
  const [planData, setPlanData] = useState([]);
  const [totalData, setTotalData] = useState(0)
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [page, setPage] = React.useState(0);

  const dispatch = useDispatch();

  useEffect(() => {
    getPlanList();
  }, [search, page]);

  const getPlanList = async () => {
    setPlanData([]);
    let query = `?search=${search}&page=${page}`;
    const res = await planList(query);
    if (res && res.status) {
      setPlanData(res.data);
      setTotalData(res.total)
    } else {
      setPlanData(null);
    }
  }


  const changeStatus = async () => {
    console.log("isModalOpen", isModalOpen);
    let res =
      isModalOpen && isModalOpen?.currentComponent == "status"
        ? await planStatusChange(isModalOpen?.id)
        : isModalOpen && isModalOpen?.currentComponent == "delete"
          ? await planDelete(isModalOpen?.id)
          : null;
    if (res?.status) {
      dispatch(
        notificationSuccess({
          show: true,
          type: "success",
          message: res.message || "Success",
        })
      );
      getPlanList();
      setIsModalOpen({
        open: false,
        currentComponent: "",
        para: "",
        head: "",
        id: "",
      });
    } else {
      dispatch(
        notificationSuccess({
          show: true,
          type: "error",
          message:
            res.message || "Something went wrong. Please try again later.",
        })
      );
    }
  };

  console.log("planList", planData);


  return (
    <>
      <Stack gap={2}>
        <Heading head={"Plan"} />
        <Box
          mb={2}
          display="flex"
          justifyContent="space-between"
          alignItems={"center"}
        >
          <Box>
            <FormControl sx={{ width: { xs: "100%", md: "500px" } }}>
              <Typography fontSize={"14px"}>Search</Typography>
              <TextField
                placeholder="Search Plan"
                variant="outlined"
                style={{ marginRight: "10px" }}
                onChange={(e) => setSearch(e.target.value)}
                value={search}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <MdSearch />
                    </InputAdornment>
                  ),
                }}
              />
            </FormControl>
          </Box>
          <Button
            style={{ Height: "fit-contant" }}
            variant="contained"
            color="primary"
            buttonName="Add Plan"
            onClick={() => {
              setIsModalOpen({
                open: true,
                currentComponent: "addplans",
              });
            }}
          />
        </Box>
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Plan Name</TableCell>
                <TableCell>Plan Price</TableCell>
                <TableCell>Expiration Duration</TableCell>
                <TableCell>Plan Duration Type</TableCell>
                <TableCell>Status</TableCell>
                <TableCell>Actions</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {planData?.map((row) => (
                <TableRow key={row.id}>
                  <TableCell>{row.planName}</TableCell>
                  <TableCell>{row.planPrice}</TableCell>
                  <TableCell>{row.planExpirationDuration + " " + row.planDurationType == 1 ? "Months" : "Days"}</TableCell>
                  <TableCell>{row.planDurationType == 1 ? "Months" : "Days"}</TableCell>
                  <TableCell>
                    <Switch
                      checked={row?.status === 1 ? true : false}
                      disabled={row?.status == 2 ? true : false}
                      onClick={(e) => {
                        e.stopPropagation();
                        setIsModalOpen({
                          id: row?.id,
                          open: true,
                          currentComponent: "status",
                          head: "Change Status",
                          para: "Are you sure you want to Change the Status?",
                        });
                      }}
                    />
                  </TableCell>
                  <TableCell>
                    <Box display={"flex"} gap={1} justifyContent={"flex-end"}>
                      <Tooltip title="Edit">
                        <IconButton
                          sx={{
                            backgroundColor: "primary.main",
                            color: "white.main",
                          }}
                          onClick={() => {
                            setIsModalOpen({
                              open: true,
                              currentComponent: "addplans",
                              isEdit: true,
                              id: row.id
                            });
                          }}
                        >
                          <MdEdit size={15} />
                        </IconButton>
                      </Tooltip>
                      <Tooltip title="Delete">
                        <IconButton
                          sx={{
                            backgroundColor: "red.main",
                            color: "white.main",
                          }}
                          onClick={() =>
                            setIsModalOpen({
                              open: true,
                              currentComponent: "delete",
                              head: "Delete Company",
                              para: "Are you sure you want to Delete this Company?",
                              id: row?.id,
                            })
                          }
                        >
                          <MdDelete size={15} />
                        </IconButton>
                      </Tooltip>
                    </Box>
                  </TableCell>
                </TableRow>
              ))}
              {planData == null ? (
                <TableRow>
                  <TableCell
                    sx={{
                      color: "silver",
                      textAlign: "center",
                      paddingTop: "90px",
                      borderBottom: "none",
                      fontSize: "30px",
                    }}
                    colSpan="6"
                  >
                    No records to display
                  </TableCell>
                </TableRow>

              ) : (
                planData && planData.length === 0 ? (<TableRowsLoader rowsNum={10} colNumber={6} />) : null
              )}
            </TableBody>
          </Table>
        </TableContainer>
        {
          planData != null && (
            <Pagination totalData={totalData}
              page={page}
              setPage={setPage}
              rowsPerPage={rowsPerPage}
              setRowsPerPage={setRowsPerPage}
            />)
        }

      </Stack>
      <Modal
        modalOpen={isModalOpen}
        handleClose={() => {
          setIsModalOpen({ open: false, currentComponent: "" })
          getPlanList();
        }}
        handelClick={changeStatus}
      />
    </>
  );
};

export default Plan;
