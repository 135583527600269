import React from 'react'
import TextField from "@mui/material/TextField";
import { Box, IconButton, Typography } from '@mui/material';
import InputAdornment from '@mui/material/InputAdornment';
import { Visibility, VisibilityOff } from '../../helper/Icons'
import { MdErrorOutline } from "react-icons/md";


const CommonInput = (props) => {
  const { type, placeholder, iconinput, labelinput, value, disabled, isDate, passwordinput, style, className } = props;
  const [showPassword, setShowPassword] = React.useState(false);
  const handleClickShowPassword = () => setShowPassword((show) => !show);

  let showIcons = isDate ? false : true;


  return (
    <>
      {labelinput && <Typography fontSize={{ xs: '14px', sm: '14px' }} fontWeight={500}>{labelinput}</Typography>}
      <Box position={'relative'} mb={1}>
        <TextField
          fullWidth
          hiddenLabel
          name={props.name}
          disabled={disabled}
          placeholder={placeholder}
          inputProps={{ maxLength: props.maxLength, min: props.min }}
          value={type === 'date' ? value : props?.formik?.values[props.name]}
          // value={value}
          type={type ? type : passwordinput ? showPassword ? 'text' : 'password' : 'text'}
          color="primary"
          sx={style}
          className={className}
          error={props?.formik?.touched[props.name] && props?.formik?.errors[props.name]}
          helperText={props?.formik?.touched[props.name] && props?.formik?.errors[props.name]}
          {...props?.formik?.getFieldProps(props.name)}
          {...props}
        />
        {(passwordinput || iconinput) &&
          passwordinput ?
          <InputAdornment position="end" sx={{ height: '100%', position: 'absolute', right: { xs: '7px', sm: '7px' }, top: { xs: '4px', sm: '4px' } }}>
            <IconButton onClick={handleClickShowPassword}>
              {showPassword ? <VisibilityOff
                style={{
                  color: props?.formik?.touched[props.name] && props?.formik?.errors[props.name] ? '#D32F2F' : '#000',
                  fontSize: '18px',
                }}
              /> : <Visibility
                style={{
                  color: props?.formik?.touched[props.name] && props?.formik?.errors[props.name] ? '#D32F2F' : '#000',
                  fontSize: '18px',
                }}
              />}
            </IconButton>
          </InputAdornment>
          : iconinput &&
          <InputAdornment position="end" sx={{ height: '100%', position: 'absolute', right: { xs: '7px', sm: '7px' }, top: { xs: '4px', sm: '4px' } }}>
            <IconButton> {iconinput} </IconButton>
          </InputAdornment>
        }
        {props?.formik?.touched[props.name] && props?.formik?.errors[props.name] && !passwordinput && showIcons &&
          <InputAdornment position="end" sx={{ height: '100%', position: 'absolute', right: { xs: '7px', sm: '13px' }, top: { xs: '3px', sm: '3px' } }}>
            <MdErrorOutline style={{
              color: '#D32F2F',
              fontSize: '20px',
              cursor: 'unset'
            }} />
          </InputAdornment>
        }

      </Box >


    </>
  )
}

export default CommonInput