import React from "react";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Grid,
  Typography,
  List,
  ListItem,
  ListItemText,
  IconButton,
  Badge,
  Box,
} from "@mui/material";

import { CloseIcon } from "../../../helper/Icons";
import { Button, Heading } from "../../../components/Common";

const referralDetails = {
  id: 1,
  status: "Accepted",
  createdAt: "2023-06-15T10:30:00Z",
  updatedAt: "2023-06-18T14:45:00Z",
  client: {
    id: "C12345",
    name: "Charli Adams",
    dateOfBirth: "2012-05-20",
    contactNumber: "+1 (555) 123-4567",
  },
  referringAgency: {
    name: "Agency A",
    department: "Social Services",
    contactPerson: "Jane Smith",
    contactEmail: "jane.smith@agencya.com",
  },
  receivingAgency: {
    name: "Agency B",
    department: "Mental Health Services",
    contactPerson: "Mike Johnson",
    contactEmail: "mike.johnson@agencyb.com",
  },
  reason:
    "Client requires specialized mental health assessment and potential ongoing therapy.",
};

export function ReferralDetails({ handleClose }) {
  return (
    <>
      <DialogTitle
        sx={{
          display: "flex",
          alignItems: "center",
          width: "100%",
          justifyContent: "flex-start",
        }}
      >
        Referral Details
        <>
          <Badge
            sx={{width: "max-content" ,ml: 5}}  
            badgeContent={referralDetails.status}
            color={
              referralDetails.status === "Accepted" ? "primary" : "warning"
            }
          />
        </>
      </DialogTitle>

      <IconButton
        aria-label="close"
        onClick={handleClose}
        sx={{
          position: "absolute",
          right: 8,
          top: 8,
          color: (theme) => theme.palette.grey[500],
        }}
      >
        <CloseIcon />
      </IconButton>
      <Divider />
      <DialogContent>
        <Grid container spacing={2}>
          {/* Client Information */}
          <Grid item xs={12}>
            <Heading smallHead={"User Information"} />
            <Divider />
            <Grid container spacing={2} mt={1}>
              <Grid item xs={12} sm={6}>
                <Box display="flex" alignItems={"center"}>
                  <Typography variant="body1">Client Name:</Typography>
                  <Typography variant="body2" sx={{ marginLeft: 1 }}>
                    {referralDetails.client.name}
                  </Typography>
                </Box>
              </Grid>
              <Grid item xs={12} sm={6}>
                <Box display="flex" alignItems={"center"}>
                  <Typography variant="body1">ID:</Typography>
                  <Typography variant="body2" sx={{ marginLeft: 1 }}>
                    {referralDetails.client.id}
                  </Typography>
                </Box>
              </Grid>
              <Grid item xs={12} sm={6}>
                <Box display="flex" alignItems={"center"}>
                  <Typography variant="body1">Date of Birth:</Typography>
                  <Typography variant="body2" sx={{ marginLeft: 1 }}>
                    {referralDetails.client.dateOfBirth}
                  </Typography>
                </Box>
              </Grid>
            </Grid>
          </Grid>
          {/* Parent Information */}
          <Grid item xs={12}>
            <Heading smallHead={"Parent Information"} />
            <Divider />
            <Grid container spacing={2} mt={1}>
              <Grid item xs={12} sm={6}>
                <Box display="flex" alignItems={"center"}>
                  <Typography variant="body1">Parent Name:</Typography>
                  <Typography variant="body2" sx={{ marginLeft: 1 }}>
                    John Doe
                  </Typography>
                </Box>
              </Grid>
              <Grid item xs={12} sm={6}>
                <Box display="flex" alignItems={"center"}>
                  <Typography variant="body1">Phone:</Typography>
                  <Typography variant="body2" sx={{ marginLeft: 1 }}>
                    +1 (555) 123-4567
                  </Typography>
                </Box>
              </Grid>
              <Grid item xs={12} sm={6}>
                <Box display="flex" alignItems={"center"}>
                  <Typography variant="body1">Email:</Typography>
                  <Typography variant="body2" sx={{ marginLeft: 1 }}>
                    johandoe212@gmail.com
                  </Typography>
                </Box>
              </Grid>
            </Grid>
          </Grid>
          {/* Agency Information */}
          {/* Agency Information */}
          <Grid item xs={12}>
            <Heading smallHead={"Agency Information"} />
            <Divider />
          </Grid>

          {/* Referring Agency */}
          <Grid item xs={12} sm={6}>
            <span className="mainH1" style={{ color: "black" }}>
              Referring Agency
            </span>
            <Box display="flex" flexDirection="column" mt={1} gap={1}>
              <Box display="flex" alignItems="center">
                <Typography variant="body1">Name:</Typography>
                <Typography variant="body2" sx={{ marginLeft: 1 }}>
                  {referralDetails.referringAgency.name}
                </Typography>
              </Box>
              <Box display="flex" alignItems="center">
                <Typography variant="body1">Department:</Typography>
                <Typography variant="body2" sx={{ marginLeft: 1 }}>
                  {referralDetails.referringAgency.department}
                </Typography>
              </Box>
              <Box display="flex" alignItems="center">
                <Typography variant="body1">Contact Person:</Typography>
                <Typography variant="body2" sx={{ marginLeft: 1 }}>
                  {referralDetails.referringAgency.contactPerson}
                </Typography>
              </Box>
              <Box display="flex" alignItems="center">
                <Typography variant="body1"> Email:</Typography>
                <Typography variant="body2" sx={{ marginLeft: 1 }}>
                  {referralDetails.referringAgency.contactEmail}
                </Typography>
              </Box>
            </Box>
          </Grid>

          {/* Receiving Agency */}
          <Grid item xs={12} sm={6}>
            <span className="mainH1" style={{ color: "black" }}>
              Receiving Agency
            </span>
            <Box display="flex" flexDirection="column" mt={1} gap={1}>
              <Box display="flex" alignItems="center">
                <Typography variant="body1">Name:</Typography>
                <Typography variant="body2" sx={{ marginLeft: 1 }}>
                  {referralDetails.receivingAgency.name}
                </Typography>
              </Box>
              <Box display="flex" alignItems="center">
                <Typography variant="body1">Department:</Typography>
                <Typography variant="body2" sx={{ marginLeft: 1 }}>
                  {referralDetails.receivingAgency.department}
                </Typography>
              </Box>
              <Box display="flex" alignItems="center">
                <Typography variant="body1">Contact Person:</Typography>
                <Typography variant="body2" sx={{ marginLeft: 1 }}>
                  {referralDetails.receivingAgency.contactPerson}
                </Typography>
              </Box>
              <Box display="flex" alignItems="center">
                <Typography variant="body1">Email:</Typography>
                <Typography variant="body2" sx={{ marginLeft: 1 }}>
                  {referralDetails.receivingAgency.contactEmail}
                </Typography>
              </Box>
            </Box>
          </Grid>
        </Grid>

        <Divider sx={{ my: 2 }} />
        <Typography variant="h6">Referral Note</Typography>
        <Typography>{referralDetails.reason}</Typography>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="tertiary" buttonName={"Close"} />

        <Button
          variant="contained"
          buttonName={"Edit Referral"}
          color="primary"
        />
      </DialogActions>
    </>
  );
}
