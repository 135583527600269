import React, { useEffect, useState } from "react";
import { BackButton, Button, Heading } from "../../components/Common";
import { Box, Card, Divider, Grid, Paper, Stack, Typography } from "@mui/material";
import { MdArrowRightAlt } from "react-icons/md";
import { useLocation, useNavigate } from "react-router-dom";
import Address_Modal from "./Address_Modal";

const plans = [
  {
    title: "Monthly Plan",
    price: "$10",
    period: "per month",
    description: "Great value for medium-term goals",
    features: ["29 Days ", "Lorem ipsum dolor sit amet Lorem ipsum dolor sit amet Lorem ipsum dolor sit amet"],
  },
];

const Check_out = () => {
  const navigate = useNavigate();

  const { state } = useLocation();

  useEffect(() => {
    console.log("statedsdsdsds", state);
  }, [state]);

  const [address, setAddress] = useState({
    firstName: "",
    lastName: "",
    addressLine1: "",
    addressLine2: "",
    city: "",
    country: "",
    postalCode: "",
  });

  const [isDialogOpen, setIsDialogOpen] = useState(false);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setAddress((prevAddress) => ({
      ...prevAddress,
      [name]: value,
    }));
  };

  const handleOpenDialog = () => {
    setIsDialogOpen(true);
  };

  const handleCloseDialog = () => {
    setIsDialogOpen(false);
  };

  useEffect(() => {
    window.history.pushState(null, document.title, window.location.href);

    const handleBackButton = (event) => {
      event.preventDefault();
      window.history.pushState(null, document.title, window.location.href);
    };

    window.addEventListener('popstate', handleBackButton);

    return () => {
      window.removeEventListener('popstate', handleBackButton);
    };
  }, []);

  const isAddressEmpty =
    !address.firstName || !address.lastName || !address.addressLine1 || !address.city || !address.country || !address.postalCode;

  return (
    <Stack p={4} style={{ backgroundColor: "#fffff" }}>
      <Paper elevation={4} sx={{ padding: 4, borderRadius: 3 }}>
        <Box display={"flex"} gap={1} alignItems={"center"}>
          <BackButton />
          <Heading head={"Check Out"} />
        </Box>
        <Grid container spacing={2} mt={3}>
          <Grid item xs={12} md={8}>
            <Box sx={{ padding: 2 }}>
              <Stack gap={2}>
                <Box display={"flex"} justifyContent={"space-between"} alignItems={"center"}>
                  <Heading head={"Address"} />
                  <Stack display={"flex"} flexDirection={"row"} gap={2}>
                    <Button
                      variant="contained"
                      color="tertiary"
                      buttonName="Edit Address"
                      onClick={handleOpenDialog}
                    />
                    <Button color="primary" buttonName="Add Address" onClick={handleOpenDialog} />
                  </Stack>
                </Box>

                {/* Display address or "No Address Found" */}
                <Box sx={{ border: "1px solid gray", padding: 2, borderRadius: "8px" }}>
                  {isAddressEmpty ? (
                    <Typography variant="body1" >
                      No Address Found
                    </Typography>
                  ) : (
                    <>
                      <Typography variant="body1">{`${address.firstName} ${address.lastName}`}</Typography>
                      <Typography variant="body1">
                        {address.addressLine1}
                        {address.addressLine2 && `, ${address.addressLine2}`}
                      </Typography>
                      <Typography variant="body1">
                        {`${address.city}, ${address.country}, ${address.postalCode}`}
                      </Typography>
                    </>
                  )}
                </Box>
              </Stack>
            </Box>
          </Grid>

          <Grid item xs={12} md={4}>
            <Card
              sx={{
                boxShadow: "0px 8px 20px rgba(0, 0, 0, 0.1)",
                borderRadius: "10px",
                border: "2px solid gray.main",
                padding: 3,
                display: "flex",
                flexDirection: "column",
                gap: 2,
                width: "100%",
              }}
            >
              <Heading smallHead={"Summary"} />
              {plans.map((plan, idx) => (
                <Box key={idx} sx={{ display: "flex", flexDirection: "column", gap: 1 }}>
                  <Box display="flex" justifyContent="space-between">
                    <Typography variant="h6" fontWeight={600}>
                      Selected Plan:
                    </Typography>
                    <Typography variant="body1" fontWeight={600}>
                      {plan.title}
                    </Typography>
                  </Box>

                  <Box display="flex" justifyContent="space-between">
                    <Typography variant="h6" fontWeight={600}>
                      Price:
                    </Typography>
                    <Typography variant="body1" fontWeight={600}>
                      {plan.price} / {plan.period}
                    </Typography>
                  </Box>

                  <Divider sx={{ my: 2 }} />

                  <Box display="flex" justifyContent="space-between">
                    <Typography variant="h6" fontWeight={600}>
                      Total Cost:
                    </Typography>
                    <Typography variant="body1" fontWeight={600}>
                      $10.00
                    </Typography>
                  </Box>
                </Box>
              ))}
              <Box>
                <Button
                  endIcon={<MdArrowRightAlt />}
                  variant="contained"
                  buttonName="Pay Now"
                  onClick={() => navigate("/success")}
                  style={{ minWidth: "100% !important" }}
                />
              </Box>
            </Card>
          </Grid>
        </Grid>
      </Paper>

      {/* Address Dialog */}
      <Address_Modal
        isDialogOpen={isDialogOpen}
        handleCloseDialog={handleCloseDialog}
        address={address}
        handleInputChange={handleInputChange}
      />
    </Stack>
  );
};

export default Check_out;
