import React, { useState } from "react";
import {
  Box,
  Card,
  CardContent,
  Divider,
  Grid,
  Typography,
  Avatar,
  Stack,
} from "@mui/material";
import agencylogo from "../../assets/images/Logo.png";
import { BackButton, Button, Heading } from "../../components/Common";

const AgencyDetailView = () => {
  const [data] = useState([
    {
      id: 1,
      user: "John Doe",
      slot: "1 hour",
      position: 1,
      status: "Active",
      service: "ABA Therapy",
    },
    {
      id: 2,
      user: "Jane Smith",
      slot: "1.5 hours",
      position: 2,
      status: "Inactive",
      service: "OT Services",
    },
    {
      id: 3,
      user: "Michael Johnson",
      slot: "2 hours",
      position: 3,
      status: "Active",
      service: "Evaluations",
    },
    {
      id: 4,
      user: "Emily Davis",
      slot: "3 hours",
      position: 4,
      status: "Inactive",
      service: "Social Skills",
    },
    {
      id: 5,
      user: "David Wilson",
      slot: "3 hours",
      position: 5,
      status: "Active",
      service: "SLP Services",
    },
  ]);

  return (
    <>
      <Box display="flex" flexDirection="row" gap={1} mb={2}>
        <BackButton />
        <Heading head="Agency Name" />
      </Box>

      <Card>
        <CardContent
          sx={{
            p: 3,
            backgroundColor: (theme) => theme.palette.background.paper,
          }}
        >
          <Box mb={2} display="flex" flexDirection="column" alignItems="center">
            <Avatar
              alt="Agency Logo"
              src={agencylogo}
              sx={{ width: 110, height: 110, mb: 2 }}
            />
            <Typography variant="h5" fontWeight="bold">
              Bright Future Therapy
            </Typography>
            <Typography variant="body2" color="textSecondary" mb={1}>
              brightfuture@gmail.com | +91 8798563254
            </Typography>
            <Typography variant="body2" color="textSecondary">
              123 Main Street, New York, NY 10001
            </Typography>
          </Box>

          <Divider sx={{ my: 2 }} />

          <Box mb={3}>
            <Typography variant="h6" fontWeight="bold" mb={1}>
              Available Services
            </Typography>
            <Box
              sx={{
                display: "flex",
                flexWrap: "wrap",
                gap: 1,
              }}
            >
              {[
                "ABA Therapy",
                "OT Services",
                "Evaluations",
                "Social Skills",
              ].map((service, index) => (
                <Box key={index} className="listbutton selectedlistbutton">
                  {service}
                </Box>
              ))}
            </Box>
          </Box>
          <Divider sx={{ my: 2 }}  />

          <Box mb={4}>
            <Typography variant="h6" fontWeight="bold" mb={1}>
              Unavailable Services
            </Typography>
            <Box
              sx={{
                display: "flex",
                flexWrap: "wrap",
                gap: 1,
              }}
            >
              {["SLP Services", "Case Management"].map((service, index) => (
                <Box key={index} className="listbutton">
                  {service}
                </Box>
              ))}
            </Box>
          </Box>

          <Box mt={2}>
            <Grid container spacing={2}>
              <Grid item xs={12} md={6}>
                <Button buttonName={"Edit"} color="secondary" fullWidth />
              </Grid>
              <Grid item xs={12} md={6}>
                <Stack direction="row" spacing={1} justifyContent={"flex-end"}>
                  <Button buttonName={"Cancel"} color="tertiary" fullWidth />
                  <Button buttonName={"Save"} color="primary" fullWidth />
                </Stack>
              </Grid>
            </Grid>
          </Box>
        </CardContent>
      </Card>
    </>
  );
};

export default AgencyDetailView;
