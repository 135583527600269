import React, { useEffect, useState } from "react";
import {
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  Typography,
} from "@mui/material";
import { CloseIcon } from "../../../helper/Icons";
import { Button, Heading, Input } from "../../Common";
import { userDetails } from "../../../Service/User.service";

const UserDetail = ({ handleClose, modalOpen, handelClick, data, handleChange, status, editId }) => {


  return (
    <>
      <DialogTitle sx={{ display: "flex", alignItems: "center" }}>
        <Heading smallHead={"User Details"} />
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>

      <DialogContent sx={{ mt: 2 }} style={{ padding: "0px !important" }}>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6}>
            <Typography variant="body1" fontWeight="bold">
              Name:
            </Typography>
            <Typography>{data.firstName + " " + data.lastName}</Typography>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Typography variant="body1" fontWeight="bold">
              Email:
            </Typography>
            <Typography>{data.email}</Typography>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Typography variant="body1" fontWeight="bold">
              Phone:
            </Typography>
            <Typography>{data.mobile}</Typography>
          </Grid>


          <Grid item xs={12} sm={12}>
            <Typography variant="body1" fontWeight="bold">
              Location:
            </Typography>
            <Typography>
              {[
                data.address1,
                data.address2,
                data.city,
                data.state,
                data.zipCode,
              ]
                .filter(Boolean)
                .join(", ")}
            </Typography>
          </Grid>
        </Grid>
      </DialogContent>

      <DialogActions>
        <Button
          onClick={handleClose}
          buttonName={"Close"}
          variant="contained"
          color="tertiary"
        />
      </DialogActions>
    </>
  );
};

export default UserDetail;
