import { useState } from "react";
import {
  Card,
  CardContent,
  CardActions,
  CardHeader,
  Typography,
  Switch,
  TextField,
  Grid,
  Stack,
  Box,
} from "@mui/material";
import { MdDoorbell } from "react-icons/md";
import { Button, Heading } from "../../../components/Common";
import { FaBell } from "react-icons/fa";

export default function Service_Availability() {
  const [services, setServices] = useState([
    { id: 1, name: "ABA Therapy", availableSlots: 5, isAvailable: true },
    { id: 2, name: "OT Services", availableSlots: 3, isAvailable: true },
    {id: 3,name: "SLP Services",availableSlots: 4,isAvailable: false},
    { id: 4, name: "Case Management", availableSlots: 3, isAvailable: true },
    { id: 5, name: "Social Skills", availableSlots: 5, isAvailable: false },
    { id: 6, name: "Evaluation", availableSlots: 3, isAvailable: true },

  ]);
  const [notificationMessage, setNotificationMessage] = useState("");

  const updateAvailability = (id, isAvailable) => {
    setServices(
      services.map((service) =>
        service.id === id ? { ...service, isAvailable } : service
      )
    );
  };

  const updateSlots = (id, slots) => {
    setServices(
      services.map((service) =>
        service.id === id ? { ...service, availableSlots: slots } : service
      )
    );
  };

  const sendNotification = () => {
    // Implement the actual notification logic
    console.log("Notification sent:", notificationMessage);
    setNotificationMessage("");
  };

  return (
    <Stack gap={2}>
      <Heading head={"Service Availability"} />
      <Grid container spacing={1}>
        {services.map((service) => (
          <Grid item xs={12} sm={6} md={4} key={service.id}>
            <Card>
              <CardHeader
                sx={{ paddingBottom: "0px !important" }}
                title={service.name}
                subheader={service.isAvailable ? "Available" : "Unavailable"}
                subheaderTypographyProps={{
                  sx: { color: service.isAvailable ? "#34A853" : "#EA4235" },
                }}
              />

              <Stack sx={{ padding: "16px", display: "flex", gap: "15px" }}>
                <Box>
                  <Typography
                    component="label"
                    htmlFor={`available-${service.id}`}
                  >
                    Availability
                  </Typography>
                  <Switch
                    id={`available-${service.id}`}
                    checked={service.isAvailable}
                    onChange={(e) =>
                      updateAvailability(service.id, e.target.checked)
                    }
                  />
                </Box>
                {/* <Box>
                  <TextField
                    id={`slots-${service.id}`}
                    label="Available Slots"
                    type="number"
                    value={service.availableSlots}
                    onChange={(e) =>
                      updateSlots(service.id, parseInt(e.target.value))
                    }
                    InputProps={{ inputProps: { min: 0 } }}
                    variant="outlined"
                    fullWidth
                  />
                </Box> */}
              </Stack>
            </Card>
          </Grid>
        ))}
      </Grid>

      <Card>
        <CardHeader
          title="Notify Families"
          subheader="Send a notification when services become available"
        />
        <CardContent>
          <TextField
            placeholder="Enter notification message here..."
            value={notificationMessage}
            onChange={(e) => setNotificationMessage(e.target.value)}
            multiline
            rows={2}
            fullWidth
            variant="outlined"
          />
        </CardContent>
        <CardActions sx={{ display: "flex", justifyContent: "flex-end" }}>
          <Button
            onClick={sendNotification}
            variant="contained"
            color="primary"
            fullWidth
            startIcon={<FaBell />}
            buttonName={"Send Notification"}
          />
        </CardActions>
      </Card>
    </Stack>
  );
}
