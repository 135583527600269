import React, { useState } from "react";
import {
  TextField,
  InputAdornment,
  IconButton,
  MenuItem,
  Box,
  Typography,
  Card,
  CardContent,
  Button,
  FormControl,
  Select,
  Chip,
  Grid,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import {
  Search,
  FilterList,
  LocationOn,
  Star,
  StarBorder,
} from "@mui/icons-material";
import { Heading } from "../../components/Common";

// Search Bar Component
function SearchBar({ onSearch }) {
  const [searchQuery, setSearchQuery] = useState("");

  const handleInputChange = (event) => {
    setSearchQuery(event.target.value);
    // Logic for auto-suggestions can be added here
  };

  return (
    <>
      <Typography mt={3}>Search</Typography>
      <TextField
        fullWidth
        value={searchQuery}
        onChange={handleInputChange}
        placeholder="Search by service type..."
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <Search />
            </InputAdornment>
          ),
          endAdornment: (
            <InputAdornment position="end">
              <IconButton>
                <FilterList />
              </IconButton>
            </InputAdornment>
          ),
        }}
        sx={{
          mb: 3,
          backgroundColor: "white",
          boxShadow: 1,
          borderRadius: 2,
        }}
      />
    </>
  );
}

// Filter Section Component
function Filters({ onFilterChange }) {
  const [availability, setAvailability] = useState("");
  const [proximity, setProximity] = useState(30);
  const [category, setCategory] = useState([]);

  const handleAvailabilityChange = (event) => {
    setAvailability(event.target.value);
    onFilterChange("availability", event.target.value);
  };

  const handleCategoryChange = (event) => {
    setCategory(event.target.value);
    onFilterChange("category", event.target.value);
  };

  return (
    <Box sx={{ display: "flex", gap: 2, mb: 3 }}>
      <FormControl>
        <Typography variant="subtitle1">Availability</Typography>
        <Select
          value={availability}
          onChange={handleAvailabilityChange}
          sx={{ minWidth: 150 }}
        >
          <MenuItem value="available_now">Available Now</MenuItem>
          <MenuItem value="this_week">This Week</MenuItem>
          <MenuItem value="fully_booked">Fully Booked</MenuItem>
        </Select>
      </FormControl>

      <FormControl fullWidth sx={{ marginLeft: "10px" }}>
        <Typography variant="subtitle1">Service Category</Typography>
        <Select
          multiple
          value={category}
          onChange={handleCategoryChange}
          renderValue={(selected) => (
            <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
              {selected.map((value) => (
                <Chip key={value} label={value} />
              ))}
            </Box>
          )}
          sx={{ minWidth: 150 }}
        >
          <MenuItem value="therapy">Therapy</MenuItem>
          <MenuItem value="counseling">Counseling</MenuItem>
          <MenuItem value="health">Health</MenuItem>
        </Select>
      </FormControl>
    </Box>
  );
}

// Result Card Component
function ResultCard({ agency }) {
  return (
    <Card                                             
      sx={{
        p: 2,  
        display: "flex",
        flexDirection: "column",
        boxShadow: 2,
        borderRadius: 2,
        ":hover": { boxShadow: 4 },
        transition: "box-shadow 0.3s ease-in-out",
        width: "100%",
      }}
    >
      <CardContent>
        <Typography variant="h6">{agency.name}</Typography>
        <Typography variant="subtitle1">{agency.serviceType}</Typography>
        <Chip
          label={agency.availability}
          color={agency.availability === "Available" ? "success" : "error"}
          size="small"
        />
        <Box mt={2} display="flex" alignItems="center">
          <LocationOn fontSize="small" />
          <Typography variant="body2">{agency.location}</Typography>
        </Box>
        <Box display="flex" alignItems="center" mt={1}>
          <Typography variant="body2">{agency.proximity} km away</Typography>
        </Box>
        <Box display="flex" alignItems="center" mt={1}>
          {Array(5)
            .fill(0)
            .map((_, i) =>
              i < agency.rating ? <Star key={i} /> : <StarBorder key={i} />
            )}
        </Box>
        <Button variant="contained" sx={{ mt: 2 }}>
          View Details
        </Button>
      </CardContent>
    </Card>
  );
}

// Main Search Screen Component
export default function SearchAgency() {
  const [filters, setFilters] = useState({
    availability: "",
    proximity: 30,
    category: [],
  });

  const [agencies, setAgencies] = useState([
    {
      name: "Helping Hands Therapy",
      serviceType: "Therapy",
      availability: "Available",
      location: "New York",
      proximity: 12,
      rating: 1.5,
    },
    {
      name: "Counseling Services Ltd.",
      serviceType: "Counseling",
      availability: "Fully Booked",
      location: "Los Angeles",
      proximity: 25,
      rating: 5,
    },
    {
      name: "Health & Wellness Center",
      serviceType: "Health",
      availability: "Available",
      location: "Chicago",
      proximity: 32,
      rating: 3,
    },
    {
      name: "Mental Health Support Group",
      serviceType: "Therapy",
      availability: "This Week",
      location: "San Francisco",
      proximity: 18,
      rating: 4,
    },
    {
      name: "Counseling Services Ltd.",
      serviceType: "Counseling",
      availability: "Fully Booked",
      location: "Los Angeles",
      proximity: 25,
      rating: 5,
    },
  ]);

  const handleFilterChange = (filterType, value) => {
    setFilters((prevFilters) => ({
      ...prevFilters,
      [filterType]: value,
    }));
  };

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <Box>
      <Heading head="Search Agency" />
      <SearchBar onSearch={() => {}} />
      <Filters onFilterChange={handleFilterChange} />
      <Grid container spacing={isMobile ? 2 : 4}>
        {agencies.map((agency, index) => (
          <Grid item xs={12} sm={6} md={4} lg={3} key={index}>
            <ResultCard agency={agency} />
          </Grid>
        ))}
      </Grid>
    </Box>
  );
}
