"use client";
import React from "react";
import { cardio } from "ldrs";

cardio.register();

const Loader = () => {
  return (
    <div
      style={{
        height: "100vh",
        width: "100%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        position: "absolute",
        zIndex: "9999999",
        background: "#fff",
        top: 0,
      }}
      className="example"
    >


      <l-cardio size="150" stroke="4" speed="1.5" color="primary.main"></l-cardio>
    </div>
  );
};

export default Loader;
