import React from "react";
import { Card, CardContent, Typography, Grid, Box } from "@mui/material";
import CancelIcon from "@mui/icons-material/Cancel";
import { Button } from "../../components/Common";
import { Link, useNavigate } from "react-router-dom";
import "./Success.css"; 

const Failed = () => {

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        height: '100vh',
        backgroundColor: '#f5f5f5',
        textAlign: 'center',
        padding: 3,
          
      }}
    >
      < >
        {" "}
        <Card sx={{ maxWidth: 600, padding: 4, borderRadius: 3, boxShadow: 3 }} className="fade-in">
          <Grid container spacing={2} direction="column" alignItems="center">
            <Grid item>
              <CancelIcon sx={{ fontSize: 80, color: "red.main" }} />
            </Grid>
            <Grid item>
              <Typography
                variant="h4"
                component="h1"
                textAlign="center"
                gutterBottom
              >
                Payment Failed
              </Typography>
            </Grid>
            <Grid item>
              <Typography
                variant="body1"
                textAlign="center"
                color="textSecondary"
                gutterBottom
              >
                Unfortunately, your payment could not be processed. Please try
                again.
              </Typography>
              <Typography
                variant="body2"
                textAlign="center"
                color="textSecondary"
              >
                If the issue persists, please contact support.
              </Typography>
            </Grid>
            <Grid item>
              <Link to="https://collaborative-aba.vercel.app/">
                <Button
                  variant="contained"
                  color="primary"
                  size="large"
                  sx={{ textTransform: "none", mt: 3 }}
                  buttonName="Try Again"
                />
              </Link>
            </Grid>
          </Grid>
        </Card>
      </>
    </Box>
  );
};

export default Failed;
