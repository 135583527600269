import React from "react";
import {
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  Typography,
  Select,
  FormControl,
  MenuItem,
} from "@mui/material";
import { CloseIcon } from "../../helper/Icons";
import { Button, FileUpload, Input } from "../../components/Common";
const CreateSlot_Modal = ({ handleClose, handleChange, status }) => {
  return (
    <>
      <DialogTitle sx={{ m: 0, p: 2 }} id="Common_modal">
        Create Slot
      </DialogTitle>
      <IconButton
        aria-label="close"
        onClick={handleClose}
        sx={{
          position: "absolute",
          right: 8,
          top: 8,
          color: (theme) => theme.palette.grey[500],
        }}
      >
        <CloseIcon />
      </IconButton>
      <DialogContent>
        <Grid container spacing={2} maxWidth={{ sm: "900px" }}>
          <Grid item xs={12} sm={12}>
            <Input
              labelinput={"Slot Name"}
              type="text"
              name="startdate"
              maxLength={50}
            />
          </Grid>
          <Grid item xs={12}>
            <Typography
              fontSize={{ xs: "10px", sm: "13px" }}
              fontWeight={500}
              mb={"2px"}
            >
              {"Slot Type"}
            </Typography>

            <FormControl fullWidth variant="outlined">
              <Select
                labelId="status-label"
                id="status"
                value={status}
                onChange={handleChange}
              >
                <MenuItem value="0">consultation</MenuItem>
                <MenuItem value="1">follow-up</MenuItem>
                <MenuItem value="2">Advice</MenuItem>
              </Select>
            </FormControl>
          </Grid>

          <Grid item xs={12} sm={6}>
            <Input labelinput={"Time Range"} type="datetime-local" maxLength={50}/>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Input labelinput={"Duration"} type="time" maxLength={50} />
          </Grid>
          <Grid item xs={12}>
            <Typography
              fontSize={{ xs: "10px", sm: "13px" }}
              fontWeight={500}
              mb={"2px"}
            >
              {"Status"}
            </Typography>

            <FormControl fullWidth variant="outlined">
              <Select
                labelId="status-label"
                id="status"
                value={status}
                onChange={handleChange}
              >
                <MenuItem value="0">Enable</MenuItem>
                <MenuItem value="1">Disable</MenuItem>
              </Select>
            </FormControl>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button
          buttonName={"Cancel"}
          size="medium"
          color="white"
          onClick={handleClose}
          style={{
            // padding: "10px 11px",
            backgroundColor: "secondary.main",
          }}

        />
        <Button
          buttonName="Create"
          size="small"
        />
      </DialogActions>
    </>
  );
};

export default CreateSlot_Modal;
