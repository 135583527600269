import React, { useState } from "react";
import {
  Grid,
  TextField,
  MenuItem,
  Select,
  FormControl,
  Typography,
  FormGroup,
  FormControlLabel,
  Checkbox,
  Stack,
  Box,
  Paper,
  Divider,
} from "@mui/material";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker, TimePicker } from "@mui/x-date-pickers";
import { BackButton, Button, Heading, Input } from "../../components/Common";

export default function CreateService() {
  const [selectedService, setSelectedService] = useState("");
  const [selectedInsurance, setSelectedInsurance] = useState("");
  const [insuranceName, setInsuranceName] = useState("");
  const [startDate, setStartDate] = useState(null);
  const [availability, setAvailability] = useState({
    monday: { available: false, from: null, to: null },
    tuesday: { available: false, from: null, to: null },
    wednesday: { available: false, from: null, to: null },
    thursday: { available: false, from: null, to: null },
    friday: { available: false, from: null, to: null },
    saturday: { available: false, from: null, to: null },
    sunday: { available: false, from: null, to: null },
  });

  const handleAvailabilityChange = (event) => {
    const { name, value, type, checked } = event.target;
    setAvailability((prev) => ({
      ...prev,
      [name]:
        type === "checkbox"
          ? { ...prev[name], available: checked }
          : { ...prev[name], [type]: value },
    }));
  };

  return (
    <Stack gap={4}>
      <Box display={"flex"} gap={1} alignItems={"center"}>
        <BackButton />
        <Heading head={"Service Request"} />
      </Box>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <Paper elevation={4} sx={{ padding: 4, borderRadius: 3 }}>
          <Grid container spacing={2}>
            {/* Select Service Dropdown */}
            <Grid item xs={12} sm={6}>
              <FormControl fullWidth>
                <Typography variant="h6">Select Service</Typography>
                <Select
                  value={selectedService}
                  onChange={(e) => setSelectedService(e.target.value)}
                  fullWidth
                >
                  <MenuItem value="">Select Services</MenuItem>
                  <MenuItem value="ABA Therapy">ABA Therapy</MenuItem>
                  <MenuItem value="OT Services">OT Services</MenuItem>
                  <MenuItem value="Evaluations">Evaluations</MenuItem>
                  <MenuItem value="Social Skills">Social Skills</MenuItem>
                  <MenuItem value="SLP Services">SLP Services</MenuItem>
                </Select>
              </FormControl>
            </Grid>

            {/* Date Init and Wait Time */}
            <Grid item xs={12} sm={6} sx={{ mt: { xs: 0, sm: 0 } }}>
              <Input
                type="date"
                labelinput={"Date Init"}
                placeholder="Date Init"
              />
            </Grid>

            <Grid item xs={12} sm={6}>
              <Input
                labelinput={"Est. Wait Time"}
                placeholder="Est. Wait Time"
              />
            </Grid>

            {/* Child's Info */}
            <Grid item xs={6} sm={6}>
              <Input labelinput={"Child's Name"} placeholder="Child's Name" />
            </Grid>

            <Grid item xs={6} sm={6}>
              <Input
                type="date"
                labelinput={"Child's DOB"}
                placeholder="Child's DOB"
              />
            </Grid>

            {/* Address Information */}
            <Grid item xs={12}>
              <Divider>Address</Divider>
            </Grid>

            <Grid item xs={12} sm={6}>
              <Input
                labelinput={"Address Line 1"}
                placeholder={"Address Line 1"}
                name="address1"
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <Input
                labelinput={"Address Line 2"}
                name="address2"
                placeholder={"Address Line 2"}
              />
            </Grid>

            <Grid item xs={4} sm={4}>
              <Input labelinput={"State"} name="state" placeholder={"State"} />
            </Grid>
            <Grid item xs={4} sm={4}>
              <Input labelinput={"City"} name="city" placeholder={"City"} />
            </Grid>
            <Grid item xs={4} sm={4}>
              <Input
                labelinput={"ZipCode"}
                name="zipCode"
                placeholder={"ZipCode"}
              />
            </Grid>

            {/* Insurance Info */}
            <Grid item xs={6}>
              <FormControl fullWidth>
                <Typography variant="h6">Insurance</Typography>
                <Select
                  value={selectedInsurance}
                  onChange={(e) => setSelectedInsurance(e.target.value)}
                  fullWidth
                >
                  <MenuItem value="yes">Yes</MenuItem>
                  <MenuItem value="no">No</MenuItem>
                </Select>
              </FormControl>
            </Grid>

            {selectedInsurance === "yes" && (
              <Grid item xs={6}>
                <Input
                  labelinput={"Insurance Name"}
                  placeholder="Insurance Name"
                  value={insuranceName}
                  onChange={(e) => setInsuranceName(e.target.value)}
                />
              </Grid>
            )}

            {/* Parent's Info */}
            <Grid item xs={6}>
              <Input labelinput={"Parent's Name"} placeholder="Parent's Name" />
            </Grid>
            <Grid item xs={6}>
              <Input
                labelinput={"Parent's Phone"}
                placeholder="Parent's Phone"
              />
            </Grid>
            <Grid item xs={6}>
              <Input labelinput={"Parent Email"} placeholder="Parent Email" />
            </Grid>

            {/* Availability */}
            <Grid item xs={12}>
              <Divider>Availability</Divider>
            </Grid>

            <Grid item xs={12} md={6}>
              <FormGroup>
                <Grid container spacing={1} alignItems="center">
                  <Grid item xs={4}></Grid>
                  <Grid item xs={4} sx={{ textAlign: "center" }}>
                    <span style={{ fontWeight: 700 }}>From</span>
                  </Grid>
                  <Grid item xs={4} sx={{ textAlign: "center" }}>
                    <span style={{ fontWeight: 700 }}>To</span>
                  </Grid>
                </Grid>
                {Object.keys(availability).map((day) => (
                  <Grid container spacing={1} key={day} alignItems="center">
                    <Grid item xs={4}>
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={availability[day].available}
                            onChange={handleAvailabilityChange}
                            name={day}
                          />
                        }
                        label={day.charAt(0).toUpperCase() + day.slice(1)}
                      />
                    </Grid>
                    <Grid item xs={4}>
                      <TimePicker
                        value={availability[day].from}
                        onChange={(newValue) =>
                          handleAvailabilityChange({
                            target: {
                              name: day,
                              value: newValue,
                              type: "from",
                            },
                          })
                        }
                        renderInput={(params) => (
                          <TextField {...params} fullWidth />
                        )}
                        disabled={!availability[day].available}
                      />
                    </Grid>
                    <Grid item xs={4}>
                      <TimePicker
                        value={availability[day].to}
                        onChange={(newValue) =>
                          handleAvailabilityChange({
                            target: { name: day, value: newValue, type: "to" },
                          })
                        }
                        renderInput={(params) => (
                          <TextField {...params} fullWidth />
                        )}
                        disabled={!availability[day].available}
                      />
                    </Grid>
                  </Grid>
                ))}
              </FormGroup>
            </Grid>

            {/* Submit Button */}
            <Grid item xs={12} mt={1}>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <Button buttonName={"Edit"} color="secondary" />
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    gap: "8px",
                  }}
                >
                  <Button buttonName={"Cancel"} color="tertiary" />
                  <Button buttonName={"Save"} color="primary" />
                </Box>
              </Box>
            </Grid>
          </Grid>
        </Paper>
      </LocalizationProvider>
    </Stack>
  );
}
