import React, { useState } from "react";
import { Button, Heading, Input, Modal } from "../../components/Common";
import { VscFilter } from "react-icons/vsc";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Stack,
  Collapse,
  Grid,
  Typography,
  Box,
  Select,
  MenuItem,
  FormControl,
  TextField,
  InputAdornment,
  IconButton,
  Switch,
  Autocomplete,
  Popover,
} from "@mui/material";
import { MdDelete, MdSearch, MdVisibility } from "react-icons/md";
import { RxUpdate } from "react-icons/rx";
import { GrTransaction } from "react-icons/gr";
import { useNavigate } from "react-router-dom";

const WaitingList = () => {
  const [search, setSearch] = useState("");
  const [page, setPage] = useState(1);
  const [isModalOpen, setIsModalOpen] = useState({
    open: false,
    currentComponent: "",
    para: "",
    head: "",
  });

  const [selected, setSelected] = useState("InActive");
  const handleChange = (event) => {
    setSelected(event.target.value);
  };

  const [showFilter, setShowFilter] = useState(false);
  const handleFilterClick = () => {
    setShowFilter(!showFilter);
  };

  const [status, setStatus] = useState("");
  const handleSelect = (event) => {
    setStatus(event.target.value);
  };

  const [services, setServices] = useState("");
  const handleService = (event) => {
    setServices(event.target.value);
  };

  const [data, setData] = useState([
    {
      id: 1,
      user: "John Doe",
      slot: "1 hours",
      position: 1,
      status: "Active",
      service: "ABA Therapy",
    },
    {
      id: 2,
      user: "Jane Smith",
      slot: "1.5 hours",
      position: 2,
      status: "Inactive",
      service: "OT Services",
    },
    {
      id: 3,
      user: "Michael Johnson",
      slot: "2 hours",
      position: 3,
      status: "Active",
      service: "Evaluations",
    },
    {
      id: 4,
      user: "Emily Davis",
      slot: "3 hours",
      position: 4,
      status: "Inactive",
      service: "Social Skills",
    },
    {
      id: 5,
      user: "David Wilson",
      slot: "3  hours",
      position: 5,
      status: "Active",
      service: "SLP Services",
    },
  ]);

  const handleUpdateStatus = (id) => {
    const updatedData = data.map((item) =>
      item.id === id
        ? { ...item, status: item.status === "Active" ? "Inactive" : "Active" }
        : item
    );
    setData(updatedData);
  };

  const handleRemove = (id) => {
    const updatedData = data.filter((item) => item.id !== id);
    setData(updatedData);
  };

  const handleAction = (id, action) => {
    switch (action) {
      case "view":
        console.log(`Viewing details for item ${id}`);
        break;
      case "update":
        handleUpdateStatus(id);
        break;
      case "delete":
        handleRemove(id);
        break;
      default:
        break;
    }
  };

  const filteredData = data.filter(
    (item) =>
      (services ? item.service === services : true) &&
      (status ? item.status === status : true)
  );

  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  return (
    <>
      <Stack spacing={2}>
        <Heading head={"Waitlist Management"} />
        <Stack
          mb={2}
          flexDirection={"row"}
          justifyContent={"space-between"}
          alignItems={"center"}
          flexShrink={1}
          flexWrap={"wrap"}
          gap={2}
        >
          <FormControl sx={{ width: { xs: "100%", sm: "500px" } }}>
            <Typography>Search</Typography>
            <TextField
              placeholder="Search Users"
              variant="outlined"
              value={search}
              onChange={(e) => setSearch(e.target.value)}
              style={{ marginRight: "10px" }}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <MdSearch />
                  </InputAdornment>
                ),
              }}
            />
          </FormControl>

          <Stack
            flexDirection={"row"}
            alignItems={"center"}
            gap={{ xs: 0, sm: 1 }}
            flexWrap={"wrap"}
            width={{ xs: "100%", sm: "auto" }}
          >
            <FormControl sx={{ width: { xs: "100%", sm: "250px" } }}>
              <Typography>Select Services</Typography>
              <Autocomplete
                value={services}
                onChange={(event, newValue) => setServices(newValue)}
                options={[
                  "ABA Therapy",
                  "OT Services",
                  "Evaluations",
                  "Social Skills",
                  "SLP Services",
                ]}
                renderInput={(params) => (
                  <Input {...params} variant="outlined" />
                )}
              />
            </FormControl>
            <Stack mt={2} gap={1} display={"flex"} flexDirection={"row"}>
              <Button
                color="primary"
                // onClick={handleFilterClick}
                buttonName="Export"
                aria-describedby={id}
                variant="contained"
                onClick={handleClick}
              />
              <Popover
                id={id}
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                  vertical: "bottom",
                }}
              >
                <Box flexDirection={"column"} display={'flex'} gap={1} p={1}>
                  <Button
                    buttonName={"CSV"}
                    color="white"
                  />
                  <Button
                    buttonName={"Excel"}
                    color="white"
                  />
                </Box>
              </Popover>
              <Button
                buttonName={<VscFilter />}
                style={{
                  padding: "10px 11px",
                  backgroundColor: "secondary.main",
                }}
                color="white"
                size="small"
                variant="contained"
                onClick={handleFilterClick}
              />
            </Stack>
          </Stack>
        </Stack>

        <Collapse in={showFilter}>
          <Stack
            sx={{ backgroundColor: "white.main" }}
            flexDirection={"row "}
            gap={2}
            flexWrap={"wrap"}
            justifyContent={"flex-end"}
            p={1.5}
            alignItems={"center"}
          >
            <Grid className="filterinput">
              <Input
                placeholder={"User Name"}
                type="text"
                labelinput="User Name"
              />
            </Grid>

            <Grid className="filterinput">
              <Input
                placeholder={"Search Position"}
                type="number"
                labelinput="Position"
              />
            </Grid>
            <Grid className="filterinput" style={{ marginTop: "-10px" }}>
              <Typography>{"Status"}</Typography>
              <Select
                fullWidth
                value={status}
                onChange={handleSelect}
                displayEmpty
              >
                <MenuItem value="">Select Status</MenuItem>
                <MenuItem value="Active">Active</MenuItem>
                <MenuItem value="Inactive">Inactive</MenuItem>
              </Select>
            </Grid>
            <Stack gap={1} flexDirection={"row"}>
              <Box>
                <Button color="primary" buttonName={"Filter"} />
              </Box>
              <Box>
                <Button
                  buttonName={"Clear"}
                  color="tertiary"
                  onClick={() => setShowFilter(false)}
                />
              </Box>
            </Stack>
          </Stack>
        </Collapse>

        <TableContainer component={Paper}>
          <Table aria-label="waitlist table">
            <TableHead>
              <TableRow>
                <TableCell align="center">ID</TableCell>
                <TableCell align="center">User/Family</TableCell>
                <TableCell align="center">Est. WaitingList Time </TableCell>
                <TableCell align="center">Position</TableCell>
                <TableCell align="center">Service</TableCell>
                <TableCell align="center">Status</TableCell>
                <TableCell align="center">Actions</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {filteredData.map((row) => (
                <TableRow key={row.id} hover>
                  <TableCell
                    align="center"
                    onClick={() => navigate("/waitlist-details")}
                  >
                    {row.id}
                  </TableCell>
                  <TableCell
                    align="center"
                    onClick={() => navigate("/waitlist-details")}
                  >
                    {row.user}
                  </TableCell>
                  <TableCell
                    align="center"
                    onClick={() => navigate("/waitlist-details")}
                  >
                    {row.slot}
                  </TableCell>
                  <TableCell
                    align="center"
                    onClick={() => navigate("/waitlist-details")}
                  >
                    {row.position}
                  </TableCell>
                  <TableCell
                    align="center"
                    onClick={() => navigate("/waitlist-details")}
                  >
                    {row.service}
                  </TableCell>
                  <TableCell align="center">
                    {/* <Typography
                      color={row.status === "Active" ? "green" : "red"}
                    >
                      {row.status}
                    </Typography> */}
                    <Switch />
                  </TableCell>
                  <TableCell align="end">
                    <Stack
                      flexDirection="row"
                      justifyContent="end"
                      alignItems="center"
                      gap={1}
                    >
                      <Select defaultValue="" displayEmpty>
                        <MenuItem value="">Select Action</MenuItem>
                        <MenuItem
                          onClick={() => navigate("/waitlist-details")}
                          value="view"
                        >
                          <MdVisibility style={{ marginRight: 8 }} />
                          View
                        </MenuItem>
                        <MenuItem
                          onClick={() => {
                            setIsModalOpen({
                              open: true,
                              currentComponent: "addReffer",
                            });
                          }}
                        >
                          <GrTransaction style={{ marginRight: 8 }} /> Refer
                        </MenuItem>
                        <MenuItem
                          onClick={() => handleAction(row.id, "update")}
                          value="update"
                        >
                          <RxUpdate style={{ marginRight: 8 }} />
                          Update
                        </MenuItem>
                        <MenuItem
                          onClick={() => handleAction(row.id, "delete")}
                          value="delete"
                        >
                          <MdDelete style={{ marginRight: 8 }} />
                          Delete
                        </MenuItem>
                      </Select>
                    </Stack>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Stack>
      <Modal
        modalOpen={isModalOpen}
        handleClose={() =>
          setIsModalOpen({ open: false, currentComponent: "" })
        }
      />
    </>
  );
};

export default WaitingList;
