import { deleteRequest, get, post, put } from "../web.request";

export const planAdd = async (data) => {
  return await post(`/addsubscriptionplan`, data);
}

export const planList = async (query) => {
  return await get(`/planList${query}`);
}

export const planDelete = async (id) => {
  return await deleteRequest(`/deleteplan/${id}`);
}

export const planUpdate = async (id, data) => {
  return await put(`/updateplan/${id}`, data);
}

export const planDetail = async (id) => {
  return await get(`/detailSubscription/${id}`);
}

export const planStatusChange = async (id) => {
  return await put(`/statusChange/${id}`);
}

//deleteDescription
export const deleteDescription = async (id) => {
  return await deleteRequest(`/deleteDescription/${id}`);
}
