import { get, post, put } from "../web.request";


export const addToCart = async (data) => {
  return await post(`/addToCart`, data);
}

export const updateUserIdCart = async (data) => {
  return await post(`/updateUserIdCart`, data);
}
