import { get, post, put } from "../web.request";

export const login = async (data) => {
  return await post(`/adminLogin`, data);
}

export const loadUser = async () => {
  return await post(`/token`);
}

export const changePassword = async (data) => {
  return await put(`/changePassword`, data);
}