import React, { useState } from "react";
import dayjs from "dayjs";
import {
  Grid,
  TextField,
  MenuItem,
  Select,
  FormControl,
  Typography,
  FormGroup,
  FormControlLabel,
  Checkbox,
  Stack,
  Box,
  Paper,
  Divider,
} from "@mui/material";
import { BackButton, Button, Heading, Input } from "../../components/Common";
import { TimePicker } from "@mui/x-date-pickers";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";

const View_Waitlist_Status = () => {
  const [selectedService, setSelectedService] = useState("ABA Therapy");
  const [isEditable, setIsEditable] = useState(false);

  const [availability, setAvailability] = useState({
    monday: { available: true, from: dayjs().set('hour', 9).set('minute', 0), to: dayjs().set('hour', 17).set('minute', 0) },
    friday: { available: false, from: dayjs().set('hour', 12).set('minute', 0), to: dayjs().set('hour', 17).set('minute', 0) },
    saturday: { available: false, from: dayjs().set('hour', 10).set('minute', 0), to: dayjs().set('hour', 17).set('minute', 0) },
    sunday: { available: false, from: dayjs().set('hour', 12).set('minute', 0), to: dayjs().set('hour', 17).set('minute', 0) },
  });

  const handleAvailabilityChange = (event) => {
    const { name, value, type, checked } = event.target;
    setAvailability((prev) => ({
      ...prev,
      [name]:
        type === "checkbox"
          ? { ...prev[name], available: checked }
          : { ...prev[name], [type]: value },
    }));
  };

  const toggleEditMode = () => {
    setIsEditable((prev) => !prev);
  };

  return (
    <Stack gap={2}>
      <Box display={"flex"} gap={1} alignItems={"center"}>
        <BackButton />
        <Heading head={"Waitlist Details"} />
      </Box>
      <Paper elevation={4} sx={{ padding: 4, borderRadius: 3 }}>
        <Grid container spacing={2}>
          {/* Select Service Dropdown */}
          <Grid item xs={12} sm={6}>
            <FormControl fullWidth>
              <Typography variant="h6">Select Service</Typography>
              <Select
                value={selectedService}
                onChange={(e) => setSelectedService(e.target.value)}
                fullWidth
                disabled={!isEditable}
              >
                <MenuItem value="">Select Services</MenuItem>
                <MenuItem value="ABA Therapy">ABA Therapy</MenuItem>
                <MenuItem value="OT Services">OT Services</MenuItem>
                <MenuItem value="Evaluations">Evaluations</MenuItem>
                <MenuItem value="Social Skills">Social Skills</MenuItem>
                <MenuItem value="SLP Services">SLP Services</MenuItem>
              </Select>
            </FormControl>
          </Grid>
          {/* Date Init and Wait Time */}
          <Grid item xs={12} sm={6} sx={{ mt: { xs: 0, sm: 0 } }}>
            <Input
              type="date"
              labelinput={"Date Init"}
              placeholder="Date Init"
              disabled={!isEditable}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <Input
              labelinput={"Est. Wait Time"}
              placeholder="30 minutes"
              disabled={!isEditable}
            />
          </Grid>
          {/* Child's Info */}
          <Grid item xs={6} sm={6}>
            <Input
              labelinput={"Child's Name"}
              placeholder="Charli Cox"
              disabled={!isEditable}
            />
          </Grid>
          <Grid item xs={6} sm={6}>
            <Input
              type="date"
              labelinput={"Child's DOB"}
              placeholder="Child's DOB"
              disabled={!isEditable}
            />
          </Grid>
          {/* Parent's Info */}
          <Grid item xs={6}>
            <Input
              labelinput={"Parent's Name"}
              placeholder="Johan Doe"
              disabled={!isEditable}
            />
          </Grid>
          <Grid item xs={6}>
            <Input
              labelinput={"Parent's Phone"}
              placeholder="+1 123 456 7890"
              disabled={!isEditable}
            />
          </Grid>
          <Grid item xs={6}>
            <Input
              labelinput={"Parent Email"}
              placeholder="DoeJohan12@gmail.com"
              disabled={!isEditable}
            />
          </Grid>
          {/* Address Information */}
          <Grid item xs={12}>
            <Divider>Address</Divider>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Input
              labelinput={"Address Line 1"}
              placeholder={"123 Main St Apt 1 "}
              name="address1"
              disabled={!isEditable}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <Input
              labelinput={"Address Line 2"}
              name="address2"
              placeholder={"-"}
              disabled={!isEditable}
            />
          </Grid>
          <Grid item xs={4} sm={4}>
            <Input
              labelinput={"State"}
              name="state"
              placeholder={"Texas"}
              disabled={!isEditable}
            />
          </Grid>
          <Grid item xs={4} sm={4}>
            <Input
              labelinput={"City"}
              name="city"
              placeholder={"New York"}
              disabled={!isEditable}
            />
          </Grid>
          <Grid item xs={4} sm={4}>
            <Input
              labelinput={"ZipCode"}
              name="zipCode"
              placeholder={"123456"}
              disabled={!isEditable}
            />
          </Grid>
          {/* Insurance Information */}
          <Grid item xs={6}>
            <Input
              labelinput={"Insurance Name"}
              placeholder="CareFirst"
              disabled={!isEditable}
            />
          </Grid>
        </Grid>
        {/* Availability */}
        <Grid item xs={12} my={2}>
          <Divider>Availability</Divider>
        </Grid>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <Grid item xs={12} md={6}>
            <FormGroup>
              <Grid container spacing={1} alignItems="center">
                <Grid item xs={4}></Grid>
                <Grid item xs={4} sx={{ textAlign: "center" }}>
                  <span style={{ fontWeight: 700 }}>From</span>
                </Grid>
                <Grid item xs={4} sx={{ textAlign: "center" }}>
                  <span style={{ fontWeight: 700 }}>To</span>
                </Grid>
              </Grid>
              {Object.keys(availability).map((day) => (
                <Grid container spacing={1} key={day} alignItems="center">
                  <Grid item xs={4}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={availability[day].available}
                          onChange={handleAvailabilityChange}
                          name={day}
                        />
                      }
                      label={day.charAt(0).toUpperCase() + day.slice(1)}
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <TimePicker
                      value={availability[day].from}
                      onChange={(newValue) =>
                        handleAvailabilityChange({
                          target: {
                            name: day,
                            value: newValue,
                            type: "from",
                          },
                        })
                      }
                      renderInput={(params) => (
                        <TextField {...params} fullWidth />
                      )}
                      disabled={!availability[day].available || !isEditable}
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <TimePicker
                      value={availability[day].to}
                      onChange={(newValue) =>
                        handleAvailabilityChange({
                          target: { name: day, value: newValue, type: "to" },
                        })
                      }
                      renderInput={(params) => (
                        <TextField {...params} fullWidth />
                      )}
                      disabled={!availability[day].available || !isEditable}
                    />
                  </Grid>
                </Grid>
              ))}
            </FormGroup>
          </Grid>
        </LocalizationProvider>

        {/* Submit Button */}
        <Grid item xs={12} mt={1}>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            {/* Toggle button between Edit and Save */}
            <Button
              buttonName={isEditable ? "Save" : "Edit"}
              color={isEditable ? "primary" : "secondary"}
              onClick={toggleEditMode} // Toggle edit mode on click
            />
            {isEditable && (
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  gap: "8px",
                }}
              >
                <Button buttonName={"Cancel"} color="tertiary" />
              </Box>
            )}
          </Box>
        </Grid>
      </Paper>
    </Stack>
  );
};

export default View_Waitlist_Status;
