import React from "react";
import {
  Card,
  CardContent,
  Typography,
  Grid,
  Box,
} from "@mui/material";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import { Button } from "../../components/Common";
import { useNavigate } from "react-router-dom";
import "./Success.css";

const Success = () => {
  const navigate = useNavigate();

  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        minHeight: "100vh",
        backgroundColor: "#f5f5f5",
      }}
    >
      <div className="fade-in"> 
        <Card sx={{ maxWidth: 600, padding: 4, borderRadius: 3, boxShadow: 3 }}>
          <Grid container spacing={2} direction="column" alignItems="center">
            <Grid item>
              <CheckCircleIcon sx={{ fontSize: 80, color: "green" }} />
            </Grid>
            <Grid item>
              <Typography
                variant="h4"
                component="h1"
                textAlign="center"
                gutterBottom
              >
                Success!
              </Typography>
            </Grid>
            <Grid item>
              <Typography variant="body1" textAlign="center" color="textSecondary" gutterBottom>
                Your payment has been successfully processed. Thank you for your purchase!
              </Typography>
              <Typography variant="body2" textAlign="center" color="textSecondary">
                You can now proceed with the next steps or return to the home page.
              </Typography>
            </Grid>
            <Grid item>
              <Button
                variant="contained"
                color="primary"
                size="large"
                sx={{ textTransform: "none", mt: 3 }}
                onClick={() => navigate("/")}
                buttonName="Go to Home"
              />
            </Grid>
          </Grid>
        </Card>
      </div>
    </Box>
  );
};

export default Success;
