import { deleteRequest, get, post, put } from "../web.request";

export const agencyList = async (qry) => {
  return await get(`/agencyList${qry}`);
}

export const agencyAdd = async (data) => {
  return await post(`/createAgency`, data);
}

export const agencyUpdate = async (id, data) => {
  return await put(`/agencyUpdate/${id}`, data);
}

export const agencyDelete = async (id) => {
  return await deleteRequest(`/agencyDelete/${id}`);
}

export const agencyDetail = async (id) => {
  return await get(`/agencyDetail/${id}`);
}

//status change
export const agencyStatusChange = async (id) => {
  return await put(`/agencyStatusUpdate/${id}`);
}

export const agencyRegister = async (data) => {
  return await post(`/agencyRegister`, data);
}
