// NotFound.js
import React from 'react';
import { Box, Typography } from '@mui/material';
import { Link } from 'react-router-dom'; // Assuming you're using React Router
import { Button } from '../../components/Common';

const NotFound = () => {
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        height: '100vh',
        backgroundColor: '#f5f5f5',
        textAlign: 'center',
        padding: 3,
      }}
    >
      <Typography variant="h1" sx={{ fontSize: '4rem', fontWeight: 'bold', color: '#1976d2' }}>
        404
      </Typography>
      <Typography variant="h5" sx={{ marginBottom: 2 }}>
        Page Not Found
      </Typography>
      <Typography variant="body1" sx={{ marginBottom: 4 }}>
        The page you are looking for does not exist.
      </Typography>
      <Button
        variant="contained"
        color="primary"
        component={Link}
        to="/"
        sx={{ padding: '10px 20px' }}
        buttonName="Go to Home"
      />
      
    </Box>
  );
};

export default NotFound;
