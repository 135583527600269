import React, { useState } from "react";
import {
  Box,
  Container,
  Grid,
  IconButton,
  Modal,
  Stack,
  Typography,
} from "@mui/material";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { FaRegUser } from "react-icons/fa";
import { MdOutlineModeEditOutline } from "react-icons/md";
import {
  BackButton,
  Button,
  FileUpload,
  Heading,
  Input,
} from "../../components/Common";
import { CloseIcon } from "../../helper/Icons";
import Header from "../../components/layouts/Header";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  borderRadius: "10px",
  boxShadow: 24,
  p: 4,
  display: "flex",
  flexDirection: "column",
  gap: 2,
};

const Agency_Profile = () => {
  const [edit, setEdit] = useState(false);
  const [file1, setfile1] = useState(null);
  const [profile, setProfile] = useState({
    Companyname: "Helth Care",
    lastName: "Doe",
    email: "john.doe@example.com",
    phone: "1234567890",
    currentplan: "Monthly",
    validity: "12/12/2023",
    address1: "123 Main St",
    address2: "",
    country: "USA",
    state: "California",
    city: "Los Angeles",
    zipCode: "90001",
    price: "$100",
  });

  const handleChange = (e) => {
    const file1 = e.target.files[0];
    if (file1) {
      const reader = new FileReader();
      reader.onload = () => {
        setfile1({ image: reader.result });
      };
      reader.readAsDataURL(file1);
    }
  };

  const handleClick = () => {
    setEdit(!edit);
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setProfile({
      ...profile,
      [name]: value,
    });
  };

  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const [open1, setOpen1] = React.useState(false);

  const handleClickOpen = () => {
    setOpen1(true);
  };

  const handleClose2 = () => {
    setOpen1(false);
  };
  return (
    <>
      <Stack
        flexDirection={"row"}
        justifyContent={"flex-start"}
        gap={1}
        alignItems={"center"}
        mb={2}
      >
        <BackButton />
        <Heading head={"Agency Profile"} />
      </Stack>
      <>
        <Container>
          <Grid container spacing={2} justifyContent={"center"}>
            {/* First Card: Agency Details */}
            <Grid item xs={12} md={4}>
              <Box className="card_container">
                <Heading smallHead={"Agency Details"} />
                <Grid container spacing={2}>
                  <Grid item xs={12} display={"flex"} justifyContent={"center"}>
                    <Box
                      sx={{
                        width: "110px",
                        height: "110px",
                        background: "#f1f1f1",
                        borderRadius: "50%",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        position: "relative",
                      }}
                    >
                      {file1 ? (
                        <img
                          src={file1.image}
                          width={110}
                          height={110}
                          alt="Agency Logo"
                          style={{ borderRadius: "50%" }}
                        />
                      ) : (
                        <FaRegUser
                          style={{ fontSize: "50px", color: "#00000091" }}
                        />
                      )}
                      {edit && (
                        <Box
                          sx={{
                            position: "absolute",
                            cursor: "pointer",
                            bottom: "14px",
                            right: "0px",
                            borderRadius: "50%",
                          }}
                        >
                          <Box
                            sx={{
                              position: "relative",
                              width: "22px",
                              height: "22px",
                            }}
                          >
                            <MdOutlineModeEditOutline
                              style={{
                                position: "relative",
                                cursor: "pointer",
                                backgroundColor: "#4385F5",
                                color: "#fff",
                                fontSize: "22px",
                                padding: "4px",
                                borderRadius: "50%",
                              }}
                            />
                            <input
                              type="file1"
                              onChange={handleChange}
                              style={{
                                position: "absolute",
                                opacity: "0",
                                width: "100%",
                                height: "100%",
                                cursor: "pointer",
                                right: "0",
                              }}
                            />
                          </Box>
                        </Box>
                      )}
                    </Box>
                  </Grid>
                  <Grid item xs={12}>
                    <Input
                      labelinput={"Agency Name"}
                      variant="standard"
                      name="Companyname"
                      value={profile.Companyname}
                      onChange={handleInputChange}
                      disabled={!edit}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <Input
                      labelinput={"Agency Email"}
                      variant="standard"
                      name="email"
                      value={profile.email}
                      disabled
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <Input
                      labelinput={"Phone"}
                      variant="standard"
                      name="phone"
                      value={profile.phone}
                      onChange={handleInputChange}
                      disabled={!edit}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <Button
                      buttonName={"Upload New Logo"}
                      color="primary"
                      onClick={handleClickOpen}
                    />
                  </Grid>
                </Grid>
              </Box>
            </Grid>

            {/* Second Card: Address Details */}
            <Grid item xs={12} md={6}>
              <Box className="card_container">
                <Heading smallHead={"Address Details"} />
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <Input
                      labelinput={"Address Line 1"}
                      variant="standard"
                      name="address1"
                      value={profile.address1}
                      onChange={handleInputChange}
                      disabled={!edit}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Input
                      labelinput={"Address Line 2"}
                      variant="standard"
                      name="address2"
                      value={profile.address2}
                      onChange={handleInputChange}
                      disabled={!edit}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <Input
                      labelinput={"State"}
                      variant="standard"
                      name="state"
                      value={profile.state}
                      onChange={handleInputChange}
                      disabled={!edit}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <Input
                      labelinput={"City"}
                      variant="standard"
                      name="city"
                      value={profile.city}
                      onChange={handleInputChange}
                      disabled={!edit}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <Input
                      labelinput={"ZipCode"}
                      variant="standard"
                      name="zipCode"
                      value={profile.zipCode}
                      onChange={handleInputChange}
                      disabled={!edit}
                    />
                  </Grid>
                </Grid>
              </Box>
            </Grid>

            {/* Third Card: Plan Details */}
            <Grid item xs={12} md={8}>
              <Box className="card_container">
                <Heading smallHead={"Plan Details"} />
                <Grid container spacing={2}>
                  <Grid item xs={6}>
                    <Input
                      labelinput={"Current Plan"}
                      variant="standard"
                      name="currentplan"
                      value={profile.currentplan}
                      onChange={handleInputChange}
                      disabled={!edit}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <Input
                      labelinput={"Validity"}
                      variant="standard"
                      name="validity"
                      value={profile.validity}
                      onChange={handleInputChange}
                      disabled={!edit}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <Input
                      labelinput={"Expiration Days Left"}
                      variant="standard"
                      name="daysLeft"
                      value={Math.ceil(
                        (new Date(profile.validity).getTime() -
                          new Date().getTime()) /
                          (1000 * 3600 * 24)
                      )}
                      disabled
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <Input
                      labelinput={"Price"}
                      variant="standard"
                      name="price"
                      value={profile.price}
                      disabled
                    />
                  </Grid>
                </Grid>
              </Box>
            </Grid>
          </Grid>
          <Grid item xs={12} mt={1}>
            <>
              <Button
                buttonName={"Update Password"}
                color="secondary"
                onClick={handleOpen}
              />
              {/* {edit ? (
              <>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      gap: "8px",
                    }}
                  >
                    <Button
                      buttonName={"Cancel"}
                      color="tertiary"
                      onClick={handleClick}
                    />
                    <Button
                      buttonName={"Save"}
                      color="primary"
                      onClick={() => setEdit(false)}
                    />
                  </Box>
                </Box>
              </>
            ) : (
              <Box display={"flex"} justifyContent={"end"}>
                <Button
                  buttonName={"Edit"}
                  onClick={handleClick}
                  color="primary"
                />
              </Box>
            )} */}
            </>
          </Grid>
        </Container>
      </>

      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Heading smallHead={"Update Password"} />

            <IconButton
              aria-label="close"
              onClick={handleClose}
              sx={{
                color: (theme) => theme.palette.grey[500],
              }}
            >
              <CloseIcon />
            </IconButton>
          </Box>

          <Box sx={{ display: "flex", flexDirection: "column" }}>
            <Typography>New Password</Typography>
            <Input type="text" fullWidth />
          </Box>
          <Box
            style={{
              display: "flex",
              justifyContent: "end",
              marginTop: "10px",
            }}
          >
            <Button buttonName={"Save"} color="primary" />
          </Box>
        </Box>
      </Modal>

      <Dialog open={open1} onClose={handleClose2}>
        <DialogTitle>Upload New Logo</DialogTitle>
        <DialogContent sx={{ width: { xs: "100%", sm: "500px" } }}>
          <Grid item xs={12} mb={1}>
            <Box mb={1}>
              <Typography fontWeight={500}>Upload Agenciy Logo</Typography>
              <span
                style={{
                  fontSize: "12px",
                  fontWeight: 300,
                  color: "gray.main",
                  marginLeft: "10px",
                }}
              >
                Upload (250px X 250px)
              </span>
            </Box>
            <FileUpload singleImage={true} />
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={handleClose2}
            buttonName={"Cancle"}
            color="tertiary"
          />
          <Button type="submit" buttonName={"Upload"} color="primary" />
        </DialogActions>
      </Dialog>
    </>
  );
};

export default Agency_Profile;
