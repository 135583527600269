import React, { useEffect, useState } from "react";
import {
  Box,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  Grid,
  IconButton,
  MenuItem,
  Select,
  Typography,
  Chip,
  Autocomplete,
  TextField,
  getDialogContentTextUtilityClass,
} from "@mui/material";
import { CloseIcon } from "../../../helper/Icons"; // Ensure this is the correct import for CloseIcon
import { Button, Input } from "..";
import { agencyAdd, agencyUpdate, agencyDelete, agencyDetail, agencyStatusChange } from "../../../Service/Agency.service";
import { useFormik } from "formik";
import { agencyAddInitialValues } from "../../../helper/initialValues";
import { notificationSuccess } from "../../../state/Action/NotificationAction";
import { useDispatch } from "react-redux";
import { agencyAddValidationSchema } from "../../../helper/validation";
import { getPincodeList } from "../../../Service/Pincode.srvice";
import Notification from "../Notification/Notification";

const Create_Agency = ({ handleClose, handelClick, modalOpen, data }) => {
  // Initialize status as an empty array to handle multiple selections
  const dispatch = useDispatch();
  const [showNotification, setShowNotification] = useState({
    show: false,
    type: '',
    message: ''
  });

  const formik = useFormik({
    initialValues: agencyAddInitialValues,
    onSubmit: async (values) => {
      if (values.state == "") {
        setShowNotification({
          show: true,
          type: 'error',
          message: 'Zip code is invalid.',
        });
        return false;
      }
      const payload = {
        "agencyName": values.name,
        "email": values.email,
        "mobile": values.mobile,
        "address1": values.address1,
        "address2": values.address2,
        "state": values.state,
        "city": values.city,
        "zipcode": values.zipCode,
        "agencyService": values.servideIds
      }
      const res = modalOpen.isEdit ? await agencyUpdate(formik.values.id, payload) : await agencyAdd(payload);

      if (res.status) {
        dispatch(notificationSuccess({
          show: true,
          type: 'success',
          message: res.message || 'Success',
        }));
        handleClose();
      } else {
        dispatch(notificationSuccess({
          show: true,
          type: 'error',
          message: res.message || 'Something went wrong.',
        }));
      }
    },
    validationSchema: agencyAddValidationSchema,
  });


  const getAddressDetails = async (pin) => {
    const res = await getPincodeList(pin);

    if (res.data.status == 'OK') {
      const location = res.data.results[0].address_components;
      const l1 = res.data.results[0]

      formik.setFieldValue('city', location.find(component => component.types.includes('locality')).long_name);

      formik.setFieldValue('state', location.find(component => component.types.includes('administrative_area_level_1')).long_name);


    } else {
      formik.setFieldValue('city', '');
      formik.setFieldValue('state', '');

    }
  }

  useEffect(() => {
    if (formik.values.zipCode != "" && formik.values.zipCode != null && formik.values.zipCode.length == 6) {
      getAddressDetails(formik.values.zipCode);
    }
  }, [formik.values.zipCode]);


  console.log('formik', modalOpen);

  useEffect(() => {
    if (modalOpen && modalOpen.isEdit && modalOpen.id) {
      getAgencyData(modalOpen.id);
    }
  }
    , [modalOpen]);

  const getAgencyData = async (id) => {
    const res = await agencyDetail(id);
    if (res.status) {
      formik.setValues({
        id: res.data.id,
        name: res.data.agencyName,
        email: res.data.email,
        mobile: res.data.mobile,
        address1: res.data.address1,
        address2: res.data.address2,
        zipCode: res.data.zipcode,
        state: res.data.state,
        city: res.data.city,
        services: res.data.agencyService.map(service => service.serviceName),
        servideIds: res.data.agencyService
      });
    }
  }


  return (
    <>
      <DialogTitle sx={{ m: 0, p: 2 }} id="Common_modal">
        {modalOpen?.isEdit ? "Edit Agency" : "Add Agency"}
      </DialogTitle>
      <IconButton
        aria-label="close"
        onClick={handleClose}
        sx={{
          position: "absolute",
          right: 8,
          top: 8,
          color: (theme) => theme.palette.grey[500],
        }}
      >
        <CloseIcon />
      </IconButton>
      <DialogContent>
        <Grid container spacing={1} maxWidth={{ sm: "600px" }}>
          <Grid item xs={12} sm={12}>
            <Input labelinput={"Agency Name"} type="text" maxLength={50} name="name" formik={formik} />
          </Grid>
          <Grid item xs={12} sm={6}>
            <Input labelinput={"Phone No"} type="text" maxLength={15} name="mobile" formik={formik} />
          </Grid>{" "}
          <Grid item xs={12} sm={6}>
            <Input labelinput={"Email"} type="text" maxLength={50} name="email" formik={formik} />
          </Grid>
          <Grid item xs={12} sm={12}>
            <Input
              labelinput={"Address Line 1"}
              placeholder={"Address Line 1"}
              name="address1"
              formik={formik}
            />
          </Grid>
          <Grid item xs={12} sm={12}>
            <Input
              labelinput={"Address Line 2"}
              name="address2"
              placeholder={"Address Line 2"}
              formik={formik}
            />
          </Grid>
          <Grid item xs={4} sm={4}>
            <Input
              labelinput={"Zip Code"}
              name="zipCode"
              placeholder={"Zip Code"}
              maxLength={6}
              formik={formik}
            />
          </Grid>
          <Grid item xs={4} sm={4}>
            <Input labelinput={"State"} name="state" placeholder={"State"} formik={formik} disabled />
          </Grid>
          <Grid item xs={4} sm={4}>
            <Input labelinput={"City"} name="city" placeholder={"City"} formik={formik} disabled />
          </Grid>
          <Grid item xs={12}>
            <Typography
              fontSize={{ xs: "10px", sm: "13px" }}
              fontWeight={500}
              mb={"2px"}
            >
              Select Service Type
            </Typography>

            <Autocomplete
              multiple
              options={data.map((option) => option.serviceName)}
              value={formik.values.services}
              onChange={(event, newValue) => {
                formik.setFieldValue('servideIds', data
                  .filter(service => newValue.includes(service.serviceName)) // Filter based on selected service names
                  .map(service => ({ serviceId: service.id })) // Map to get objects in the desired format
                );
                formik.setFieldValue('services', newValue);
              }}
              renderInput={(params) => <TextField {...params} />}
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button
          buttonName="Cancel"
          size="small"
          color="tertiary"
          onClick={handleClose}
        />
        <Button
          buttonName="Add"
          size="small"
          color="primary"
          onClick={formik.handleSubmit}
        />
      </DialogActions>
      <Notification show={showNotification} setShow={setShowNotification} />

    </>
  );
};

export default Create_Agency;
