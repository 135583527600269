import React, { useEffect, useState } from "react";
import {
  Container,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  IconButton,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  Stack,
  Box,
  InputAdornment,
  FormControl,
  Chip,
  Tooltip,
  Switch,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import { Button, Heading, Modal } from "../../../components/Common";
import { MdDelete, MdEdit, MdSearch } from "react-icons/md";
import { serviceList, serviceDelete, serviceUpdate, serviceAdd, serviceStatusChange } from "../../../Service/ServiceDepartment.service";
import moment from "moment";
import TableRowsLoader from "../../../components/Loader/Skeleton";
import { useDispatch } from "react-redux";
import { notificationSuccess } from "../../../state/Action/NotificationAction";
import { addServiceInitialValues } from "../../../helper/initialValues";
import { serviceValidationSchema } from "../../../helper/validation";
import { useFormik } from "formik";
import CommonInput from "../../../components/Common/Input";
import { Pagination } from "../../../components/Common/Table";



const Service_Management = () => {
  const [dialogOpen, setDialogOpen] = useState(false);
  const [viewDialogOpen, setViewDialogOpen] = useState(false);
  const [editMode, setEditMode] = useState(false);
  const [page, setPage] = React.useState(0);
  const [serviceData, setServiceData] = useState([]);
  const [totalData, setTotalData] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [search, setSearch] = useState("");
  const [isModalOpen, setIsModalOpen] = useState({
    open: false,
    currentComponent: "",
    para: "",
    head: "",
  });
  const dispatch = useDispatch();

  const getServiceList = async () => {
    setServiceData([]);
    let selectQry = `search=${search}`;
    const response = await serviceList(selectQry);
    if (response && response.status) {
      setServiceData(response.data);
      setTotalData(response.total);
    } else {
      setServiceData(null);
    }
  };

  const handleClickOpen = (service) => {
    setEditMode(!!service);
    setDialogOpen(true);
    if (service) {
      formik.setValues({
        serviceName: service?.serviceName || "",
        id: service?.id || "",
        isEdit: true
      });
    }

  };


  const formik = useFormik({
    initialValues: addServiceInitialValues,
    onSubmit: async (value) => {
      const payload = {
        serviceName: value.serviceName,
      };

      let res = formik?.values?.isEdit ? await serviceUpdate(value.id, payload) : await serviceAdd(payload);
      if (res?.status) {
        dispatch(notificationSuccess({
          show: true,
          type: 'success',
          message: res.message || 'Success',
        }));
        formik.resetForm();
        getServiceList();

        handleClose();
      } else {
        dispatch(notificationSuccess({
          show: true,
          type: 'error',
          message: res.message || 'Something went wrong. Please try again later.',
        }));
      }
    },
    validationSchema: serviceValidationSchema,
  });

  const handleClose = () => {
    setDialogOpen(false);
    setViewDialogOpen(false);
    formik.resetForm();

  };

  useEffect(() => {
    getServiceList();
  }, [search]);



  const changeStatus = async () => {
    let res = isModalOpen && isModalOpen?.currentComponent == "status" ? await serviceStatusChange(isModalOpen?.id) : isModalOpen && isModalOpen?.currentComponent == "delete" ? await serviceDelete(isModalOpen?.id) : null;
    if (res?.status) {
      dispatch(notificationSuccess({
        show: true,
        type: 'success',
        message: res.message || 'Success'
      }))
      getServiceList();
      setIsModalOpen({ open: false, currentComponent: "", para: '', head: '', id: '' });
    } else {
      dispatch(notificationSuccess({
        show: true,
        type: 'error',
        message: res.message || 'Something went wrong. Please try again later.'
      }
      ))
    }
  }
  return (
    <>
      <Stack spacing={2}>
        <Heading head={"Service Management"} />
        <Box
          sx={{
            marginBottom: 4,
            display: "flex",
            flexDirection: { xs: "column", sm: "row" },
            justifyContent: "space-between",
            alignItems: "center",
            gap: 2,
          }}
        >
          <FormControl sx={{ width: { xs: "100%", sm: "500px" } }}>
            <TextField
              placeholder="Search"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <MdSearch />
                  </InputAdornment>
                ),
              }}
              fullWidth
              value={search}
              onChange={(e) => setSearch(e.target.value)}
            />
          </FormControl>
          <Box
            sx={{
              display: "flex",
              flexDirection: 'row',
              gap: 2,
              justifyContent: { xs: "end", sm: "flex-end" },
              width: { xs: "100%", sm: "500px" },
            }}
          >
            <Button
              variant="contained"
              color="primary"
              startIcon={<AddIcon />}
              onClick={() => handleClickOpen()}
              buttonName={"Add New Service"}
            />

          </Box>
        </Box>
        <TableContainer component={Paper} sx={{ mt: 2 }}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Service Name</TableCell>
                <TableCell>Created At</TableCell>
                <TableCell>Status</TableCell>
                <TableCell>Actions</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {serviceData?.map((service) => (
                <TableRow key={service.id}>
                  <TableCell>{service.serviceName}</TableCell>
                  <TableCell>{moment(service.createdAt).format("DD/MM/YYYY")}</TableCell>
                  <TableCell
                    className="table-hover"
                  >
                    <Switch size="small"
                      checked={service?.status === 1 ? true : false}
                      disabled={service?.status == 2 ? true : false}
                      onClick={(e) => {
                        e.stopPropagation();
                        setIsModalOpen({ id: service?.id, open: true, currentComponent: "status", head: "Change Status", para: "Are you sure you want to Change the Status?" })
                      }
                      }
                    />
                  </TableCell>
                  <TableCell>
                    <Box display={"flex"} gap={1} justifyContent={"flex-end"}>
                      <Tooltip title="Edit">
                        <IconButton
                          onClick={() => handleClickOpen(service)}
                          sx={{
                            backgroundColor: "primary.main",
                            color: "white.main",
                          }}
                        >
                          <MdEdit size={15} />
                        </IconButton>
                      </Tooltip>
                      {/* <Tooltip title="View Agencies">
                        <IconButton
                          onClick={() => handleViewOpen(service)}
                          sx={{
                            backgroundColor: "primary.main",
                            color: "white.main",
                          }}
                        >
                          <BsEye size={15} />
                        </IconButton>
                      </Tooltip> */}
                      <Tooltip title="Delete">
                        <IconButton
                          onClick={() => {
                            setIsModalOpen({
                              open: true,
                              currentComponent: "delete",
                              id: service.id,
                              para: "Are you sure you want to delete this service?",
                              head: "Service",
                            })
                          }
                          }
                          sx={{
                            backgroundColor: "red.main",
                            color: "white.main",
                          }}
                        >
                          <MdDelete size={15} />
                        </IconButton>
                      </Tooltip>
                    </Box>
                  </TableCell>
                </TableRow>
              ))}
              {serviceData == null ? (
                <TableRow>
                  <TableCell
                    sx={{
                      color: "silver",
                      textAlign: "center",
                      paddingTop: "90px",
                      borderBottom: "none",
                      fontSize: "30px",
                    }}
                    colSpan="6"
                  >
                    No records to display
                  </TableCell>
                </TableRow>
              ) : (
                serviceData && serviceData.length == 0 ? (<TableRowsLoader colNumber={4} rowsNum={10} />) : ("")
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <div style={{ display: "flex", justifyContent: "flex-end" }}>
          {serviceData != null && (
            <Pagination count={10} shape="rounded"
              totalData={totalData}
              page={page}
              setPage={setPage}
              rowsPerPage={rowsPerPage}
              setRowsPerPage={setRowsPerPage}
            />
          )}
        </div>

        {/* Edit/Add Service Dialog */}
        <Dialog open={dialogOpen} onClose={handleClose}>
          <DialogTitle>
            {formik.values.isEdit ? "Edit Service" : "Add New Service"}
          </DialogTitle>
          <DialogContent sx={{ width: { xs: "100%", sm: "500px" } }}>
            <Typography variant="subtitle1">Service Name:</Typography>
            <CommonInput
              formik={formik}
              name="serviceName"
              placeholder="Enter Service Name"
              maxLength={100}
            />
          </DialogContent>
          <DialogActions>
            <Button
              onClick={handleClose}
              color="tertiary"
              buttonName={"Cancel"}
            />
            <Button
              onClick={formik.handleSubmit}
              color="primary"
              buttonName={"Create"}
            />
          </DialogActions>
        </Dialog>

        {/* View Agencies Dialog */}
        {/* <Dialog open={viewDialogOpen} onClose={handleClose}>
          <DialogTitle>Agencies Offering {currentService?.name}</DialogTitle>
          <DialogContent>
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                gap: 1,
                flexWrap: "wrap",
                width: { xs: "100%", sm: "500px" },
              }}
            >
              {currentService?.agencies.map((agency, index) => (
                <Chip
                  key={index}
                  label={agency}
                  sx={{ margin: "2px", width: "fit-content" }}
                />
              ))}
            </Box>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={handleClose}
              color="tertiary"
              buttonName={"Close"}
            />
          </DialogActions>
        </Dialog> */}
      </Stack>
      <Modal
        modalOpen={isModalOpen}
        handleClose={() => {
          setIsModalOpen({ open: false, currentComponent: "", id: "", isEdit: "" });
        }}
        handelClick={changeStatus}
      />
    </>
  );
};

export default Service_Management;
