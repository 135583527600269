// import { Check } from "@mui/icons-material";
// import {
//   Card,
//   CardContent,
//   CardHeader,
//   Grid,
//   Typography,
//   Box,
//   Divider,
//   Stack,
//   Paper,
// } from "@mui/material";
// import React from "react";
// import { MdCheck } from "react-icons/md";
// import { Button, Heading } from "../../components/Common";
// import { useNavigate } from "react-router-dom";

// const plans = [
//   {
//     title: "Free Plan",
//     price: "$0",
//     period: "per month",
//     description: "Perfect for short-term projects",
//     features: [
//       "7 Days Free Trial",
//       "Lorem ipsum dolor sit amet Lorem ipsum dolor sit amet Lorem ipsum dolor sit amet",
//     ],
//   },
//   {
//     title: "Monthly Plan",
//     price: "$10",
//     period: "per month",
//     description: "Great value for medium-term goals",
//     features: [
//       "29 Days",
//       "Lorem ipsum dolor sit amet Lorem ipsum dolor sit amet Lorem ipsum dolor sit amet",
//     ],
//   },
//   {
//     title: "Annual Plan",
//     price: "$60",
//     period: "per annum",
//     description: "Best value for long-term success",
//     features: [
//       "365 Days",
//       "Lorem ipsum dolor sit amet Lorem ipsum dolor sit amet Lorem ipsum dolor sit amet",
//     ],
//   },
// ];

// const Our_Plan = () => {
//   const navigate = useNavigate();

//   return (
//     <Stack p={4} style={{ backgroundColor: '#f5f5f5', height: '100vh', }}>
//       <Paper elevation={4} sx={{ padding: 4, borderRadius: 3 }}>
//         <Divider>
//           <Typography
//             sx={{ textAlign: "center" }}
//             fontSize={{ xs: "24px", sm: "30px" }}
//             fontWeight={700}
//             color="text.primary"
//           >
//             Our Plans
//           </Typography>
//         </Divider>
//         <Grid container spacing={4} justifyContent="center" mt={3}>
//           {plans.map((plan, idx) => (
//             <Grid item xs={12} sm={6} md={4} key={idx}>
//               <Card
//                 sx={{
//                   width: "100%",
//                   boxShadow: "0px 8px 20px rgba(0, 0, 0, 0.1)",
//                   borderRadius: "12px",
//                   transition: "transform 0.3s ease, box-shadow 0.3s ease",
//                   ":hover": {
//                     transform: "scale(1.05)",
//                     boxShadow: "0px 12px 24px rgba(0, 0, 0, 0.2)",
//                   },
//                   backgroundColor: "#fff",
//                 }}
//               >
//                 <CardHeader
//                   title={
//                     <Typography
//                       variant="h6"
//                       fontSize={{ xs: "18px", sm: "24px" }}
//                       fontWeight={700}
//                       color="text.primary"
//                     >
//                       {plan.title}
//                     </Typography>
//                   }
//                   subheader={
//                     <Typography
//                       variant="body2"
//                       color="text.secondary"
//                       className="text-left"
//                       fontSize={{ xs: "12px", sm: "14px" }}
//                     >
//                       {plan.description}
//                     </Typography>
//                   }
//                   className="bg-gray-100 py-2"
//                 />
//                 <CardContent>
//                   <Box
//                     display={"flex"}
//                     justifyContent={"center"}
//                     alignItems={"baseline"}
//                     gap={1}
//                     my={2}
//                   >
//                     <Typography
//                       variant="h3"
//                       fontSize={{ xs: "32px", sm: "40px" }}
//                       fontWeight={700}
//                       color="primary.main"
//                     >
//                       {plan.price}
//                     </Typography>
//                     <Typography
//                       variant="subtitle1"
//                       color="textSecondary"
//                       fontSize={{ xs: "14px", sm: "16px" }}
//                     >
//                       {plan.period}
//                     </Typography>
//                   </Box>
//                   <Box my={2}>
//                     {plan.features.map((feature, idx) => (
//                       <Grid
//                         container
//                         key={idx}
//                         spacing={1}
//                         alignItems="center"
//                         mb={1}
//                       >
//                         <Grid item>
//                           <MdCheck
//                             style={{
//                               width: "1.2rem",
//                               height: "1.2rem",
//                               color: "#34A853",
//                             }}
//                           />
//                         </Grid>
//                         <Grid item xs>
//                           <Typography
//                             variant="body2"
//                             fontSize={{ xs: "12px", sm: "14px" }}
//                             color="text.primary"
//                           >
//                             {feature}
//                           </Typography>
//                         </Grid>
//                       </Grid>
//                     ))}
//                   </Box>
//                   <Button
//                     variant="contained"
//                     color="primary"
//                     buttonName={"Choose Plan"}
//                     onClick={() => navigate("/add-to-cart")}
//                     fullWidth
//                     sx={{ mt: 3, py: 1 }}
//                   />
//                 </CardContent>
//               </Card>
//             </Grid>
//           ))}
//         </Grid>
//       </Paper>
//     </Stack>
//   );
// };

// export default Our_Plan;


import { Check } from "@mui/icons-material";
import {
  Card,
  CardContent,
  CardHeader,
  Grid,
  Typography,
  Box,
  Divider,
  Stack,
  Paper,
} from "@mui/material";
import React from "react";
import { MdCheck } from "react-icons/md";
import { Button, Heading } from "../../components/Common";
import { useNavigate } from "react-router-dom";
import { planList } from "../../Service/Plan.service";
const plans = [
  {
    title: "Free Plan",
    price: "$0",
    period: "per month",
    description: "Perfect for short-term projects",
    features: [
      "7 Days Free Trial",
      "Lorem ipsum dolor sit amet Lorem ipsum dolor sit amet Lorem ipsum dolor sit amet",
    ],
  },
  {
    title: "Monthly Plan",
    price: "$10",
    period: "per month",
    description: "Great value for medium-term goals",
    features: [
      "29 Days",
      "Lorem ipsum dolor sit amet Lorem ipsum dolor sit amet Lorem ipsum dolor sit amet",
    ],
  },
  {
    title: "Annual Plan",
    price: "$60",
    period: "per annum",
    description: "Best value for long-term success",
    features: [
      "365 Days",
      "Lorem ipsum dolor sit amet Lorem ipsum dolor sit amet Lorem ipsum dolor sit amet",
    ],
  },
];

const Our_Plan = () => {
  const navigate = useNavigate();
  const [planData, setPlanData] = React.useState([]);

  React.useEffect(() => {
    getPlanList();
  }, []);

  const getPlanList = async () => {
    let selectQry = `?status=1`;
    const res = await planList(selectQry);
    if (res?.status) {
      setPlanData(res.data);
    }
  }

  console.log("planList", planData);

  return (
    <Stack p={4} style={{ backgroundColor: '#f5f5f5', height: '100vh', }}>
      <Paper elevation={4} sx={{ padding: 4, borderRadius: 3 }}>
        <Divider>
          <Typography
            sx={{ textAlign: "center" }}
            fontSize={{ xs: "24px", sm: "30px" }}
            fontWeight={700}
            color="text.primary"
          >
            Our Plans
          </Typography>
        </Divider>
        <Grid container spacing={4} justifyContent="center" mt={3}>
          {planData && planData?.map((plan, idx) => (
            <Grid item xs={12} sm={6} md={4} key={idx}>
              <Card
                sx={{
                  width: "100%",
                  boxShadow: "0px 8px 20px rgba(0, 0, 0, 0.1)",
                  borderRadius: "12px",
                  transition: "transform 0.3s ease, box-shadow 0.3s ease",
                  ":hover": {
                    transform: "scale(1.05)",
                    boxShadow: "0px 12px 24px rgba(0, 0, 0, 0.2)",
                  },
                  backgroundColor: "#fff",
                }}
              >
                <CardHeader
                  title={
                    <Typography
                      variant="h6"
                      fontSize={{ xs: "18px", sm: "24px" }}
                      fontWeight={700}
                      color="text.primary"
                    >
                      {plan.planName}
                    </Typography>
                  }
                  subheader={
                    <Typography
                      variant="body2"
                      color="text.secondary"
                      className="text-left"
                      fontSize={{ xs: "12px", sm: "14px" }}
                    >
                      {`${plan.planExpirationDuration} ${plan.planDurationType == "1" ? "Months" : "Years"} Subscription`}
                    </Typography>

                  }
                  className="bg-gray-100 py-2"
                />
                <CardContent>
                  <Box
                    display={"flex"}
                    justifyContent={"center"}
                    alignItems={"baseline"}
                    gap={1}
                    my={2}
                  >
                    <Typography
                      variant="h3"
                      fontSize={{ xs: "32px", sm: "40px" }}
                      fontWeight={700}
                      color="primary.main"
                    >
                      ${plan.planPrice}
                    </Typography>
                    <Typography
                      variant="subtitle1"
                      color="textSecondary"
                      fontSize={{ xs: "14px", sm: "16px" }}
                    >
                      {plan.planDurationType == "1" ? "per month" : "per annum"}
                    </Typography>
                  </Box>
                  <Box my={2}>
                    {plan?.planDescriptions?.map((feature, idx) => (
                      <Grid
                        container
                        key={idx}
                        spacing={1}
                        alignItems="center"
                        mb={1}
                      >
                        <Grid item>
                          <MdCheck
                            style={{
                              width: "1.2rem",
                              height: "1.2rem",
                              color: "#34A853",
                            }}
                          />
                        </Grid>
                        <Grid item xs>
                          <Typography
                            variant="body2"
                            fontSize={{ xs: "12px", sm: "14px" }}
                            color="text.primary"
                          >
                            {feature?.subValue}
                          </Typography>
                        </Grid>
                      </Grid>
                    ))}
                  </Box>
                  <Button
                    variant="contained"
                    color="primary"
                    buttonName={"Choose Plan"}
                    onClick={() => navigate(`/add-to-cart?key=${plan?.planKey}&plan=${plan.id}`)}
                    fullWidth
                    sx={{ mt: 3, py: 1 }}
                  />
                </CardContent>
              </Card>
            </Grid>
          ))}
        </Grid>
      </Paper>
    </Stack>
  );
};

export default Our_Plan;
