import React from "react";
import {
  Autocomplete,
  Box,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  Grid,
  IconButton,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import { CloseIcon } from "../../../helper/Icons";
import { Button, Input } from "..";

const options = ["Bright Future Therapy", "Pathway Counseling Services","Wellness Center for Kids","Family First Home Care","Hope and Healing Clinic"];
const Reffer_modal = ({
  handleClose,
  modalOpen,
  handelClick,
  handleChange,
  status,
}) => {
  const [value, setValue] = React.useState(options[0]);
  const [inputValue, setInputValue] = React.useState("");

  return (
    <>
      <DialogTitle sx={{ m: 0, p: 2 }} id="Common_modal">
      Select Refer Agency
      </DialogTitle>
      <IconButton
        aria-label="close"
        onClick={handleClose}
        sx={{
          position: "absolute",
          right: 8,
          top: 8,
          color: (theme) => theme.palette.grey[500],
        }}
      >
        <CloseIcon />
      </IconButton>
      <DialogContent sx={{ maxWidth:{sm: "500px"} }}>
        <Grid container spacing={2} >
          <Grid item xs={12} sm={12}>
            <Autocomplete
              value={value}
              onChange={(event, newValue) => {
                setValue(newValue);
              }}
              inputValue={inputValue}
              onInputChange={(event, newInputValue) => {
                setInputValue(newInputValue);
              }}
              id="controllable-states-demo"
              options={options}
              renderInput={(params) => (
                <Input {...params} labelinput="Select Agency" />
              )}
            />
          </Grid>
          <Grid item xs={12}>
            <Typography
              fontSize={{ xs: "10px", sm: "13px" }}
              fontWeight={500}
              mb={"2px"}
            >
              {"Service"}
            </Typography>

            <FormControl fullWidth variant="outlined">
              <Select
                labelId="status-label"
                id="status"
                value={status}
                onChange={handleChange}
              >
                <MenuItem value="">Select Services</MenuItem>
                <MenuItem value="ABA Therapy">ABA Therapy</MenuItem>
                <MenuItem value="OT Services">OT Services</MenuItem>
                <MenuItem value="Evaluations">Evaluations</MenuItem>
                <MenuItem value="Social Skills">Social Skills</MenuItem>
                <MenuItem value="SLP Services">SLP Services</MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={12}>
            <Input
              labelinput={"Note"}
              type="text"
              multiline
              rows={3}
              placeholder={"Enter Note..."}
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button
          buttonName="Cancle"
          size="small"
          color="tertiary"
          onClick={handleClose}
        />
        <Button
          buttonName="Add"
          size="small"
          color="primary"
          onClick={handelClick}
        />
      </DialogActions>
    </>
  );
};

export default Reffer_modal;
