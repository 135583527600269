import { deleteRequest, get, post, put } from "../web.request";

export const serviceList = async (selectQry) => {
  return await get(`/serviceList?${selectQry}`);
}

export const serviceAdd = async (data) => {
  return await post(`/createService`, data);
}

export const serviceUpdate = async (id, data) => {
  return await put(`/updateService/${id}`, data);
}

export const serviceDelete = async (id) => {
  return await deleteRequest(`/deleteService/${id}`);
}

export const serviceDetail = async (id) => {
  return await post(`/getService/${id}`);
}

//status change 
export const serviceStatusChange = async (id) => {
  return await put(`/statusChangeService/${id}`);
}

export const serviceDropdown = async () => {
  return await get(`/serviceDropdown`);
}