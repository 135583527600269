import {
  Box,
  Collapse,
  FormControl,
  Grid,
  MenuItem,
  Select,
  Stack,
  Switch,
  Typography,
} from "@mui/material";
import React from "react";
import {
  Button,
  Heading,
  Input,
  MenuButton,
  Modal,
} from "../../components/Common";
import Search from "../../components/Common/Table/Search";
import { VscFilter } from "react-icons/vsc";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  IconButton,
} from "@mui/material";
import { MdDelete, MdEdit, MdMoreVert } from "react-icons/md";
import { useState } from "react";

const slots = [
  {
    id: 2121,
    name: "Slot 1",
    type: "Regular",
    timeRange: "9:00 AM - 10:00 AM",
    status: "Active",
  },
  {
    id: 3212,
    name: "Slot 2",
    type: "VIP",
    timeRange: "10:00 AM - 11:00 AM",
    status: "Inactive",
  },
  {
    id: 3211,
    name: "Slot 3",
    type: "Regular",
    timeRange: "9:00 AM - 10:00 AM",
    status: "Active",
  },
  {
    id: 4321,
    name: "Slot 4",
    type: "VIP",
    timeRange: "11:00 AM - 12:00 AM",
    status: "Inactive",
  },
];
const SlotManagement = () => {
  const [search, setSearch] = React.useState("");
  const [page, setPage] = React.useState(1);
  const [isModalOpen, setIsModalOpen] = useState({
    open: false,
    currentComponent: "",
    para: "",
    head: "",
  });
  
  const [selected, setSelected] = useState("InActive");
  const handleChange = (event) => {
    setSelected(event.target.value);
  };

  const clearFilter = () => {
    setShowFilter(!showFilter);
  };
  const [showFilter, setShowFilter] = useState(false);
  const handleFilterClick = () => {
    setShowFilter(!showFilter);
  };
  const [status, setstatus] = useState("");
  const handleSelect = (event) => {
     setstatus(event.target.value);
  };
  return (
    <>
      <Stack spacing={2}>
        <Heading head={"Slot Management"} />
        <Stack
          mb={1}
          flexDirection={"row"}
          justifyContent={"space-between"}
          alignItems={"center"}
        >
          <Search search={search} setSearch={setSearch} setPage={setPage} />
          <Stack flexDirection={"row"} alignItems={"center"} gap={1}>
            <Button
              buttonName={"Create Slot"}
              size="small"
              style={{ color: "white.main" }}
              onClick={() => {
                setIsModalOpen({
                  open: true,
                  currentComponent: "createslot",
                });
              }}
            />
            <Button
              buttonName={<VscFilter />}
              style={{
                padding: "10px 11px",
                backgroundColor: "secondary.main",
              }}
              color="white"
              size="small"
              variant="contained"
              onClick={handleFilterClick}
              sx={{ mt: 2 }}
            />
          </Stack>
        </Stack>
        <Collapse Collapse in={showFilter}>
          <Stack
            mb={"12px"}
            sx={{ backgroundColor: "white.main" }}
            flexDirection={"row "}
            gap={2}
            flexWrap={"wrap"}
            justifyContent={"flex-end"}
            p={1.5}
            alignItems={"end"}
          >
            <Grid className="filterinput">
              <Typography className="inputfont">{"Slot ID"}</Typography>
              <Input placeholder={"Search Slot ID"} type="text" />
            </Grid>
            <Grid className="filterinput">
              <Typography className="inputfont">{"Slot Name"}</Typography>
              <Input placeholder={"Search Slot Name"} type="text" />
            </Grid>

            <Grid className="filterinput">
              <Typography className="inputfont">{"Slot Type"}</Typography>
              <Input placeholder={"Search Slot Type"} type="text" />
            </Grid>
            <Grid className="filterinput">
              <Typography className="inputfont">{"Start time"}</Typography>
              <Input type="time" />
            </Grid>
            <Grid className="filterinput">
              <Typography className="inputfont">{"End time"}</Typography>
              <Input type="time" />
            </Grid>
            <Grid className="filterinput">
              <Typography className="inputfont">{"Status"}</Typography>
              <Select
                displayEmpty
                fullWidth
                value={status} // Controlled value
                onChange={handleSelect}
              >
                <MenuItem value="" hidden disabled>
                  Select Status
                </MenuItem>
                <MenuItem value="Active">Active</MenuItem>
                <MenuItem value="InActive">InActive</MenuItem>
              </Select>
            </Grid>
            <Stack gap={1} flexDirection={"row"}>
              <Box>
                <Button buttonName={"Filter"} />
              </Box>
              <Box>
                <Button buttonName={"Clear"}  color='tertiary' onClick={() => clearFilter()} />
              </Box>
            </Stack>
          </Stack>
        </Collapse>
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Slot ID</TableCell>
                <TableCell>Slot Name</TableCell>
                <TableCell>Slot Type</TableCell>
                <TableCell>Time Range</TableCell>
                <TableCell>Status</TableCell>
                <TableCell>Actions</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {slots.map((slot) => (
                <TableRow key={slot.id}  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
                  <TableCell>{slot.id}</TableCell>
                  <TableCell>{slot.name}</TableCell>
                  <TableCell>{slot.type}</TableCell>
                  <TableCell>{slot.timeRange}</TableCell>
                  <TableCell
                    className="table-hover"
                    style={{ textAlign: "center" }}
                  >
                    <FormControl>
                      <Select value={selected} onChange={handleChange}>
                        <MenuItem value="Active">Active</MenuItem>
                        <MenuItem value="InActive">InActive</MenuItem>
                      </Select>
                    </FormControl>
                  </TableCell>
                  <TableCell>
                    <IconButton
                      sx={{
                        backgroundColor: "secondary.main",
                        color: "white.main",
                        marginRight: "5px",
                      }}
                    >
                      <MdEdit size={15} />
                    </IconButton>
                    <IconButton
                      sx={{ backgroundColor: "red.main", color: "white.main" }}
                    >
                      <MdDelete size={15} />
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Stack>
      <Modal
        modalOpen={isModalOpen}
        handleClose={() =>
          setIsModalOpen({ open: false, currentComponent: "" })
        }
      />
    </>
  );
};

export default SlotManagement;
