import React, { useEffect, useState } from "react";
import {
  Box,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
} from "@mui/material";
import { CloseIcon } from "../../../helper/Icons";
import { Button, Input } from "..";
import { userInitialValues } from "../../../helper/initialValues";
import { useFormik } from "formik";
import { userValidationSchema } from "../../../helper/validation";
import { getPincodeList } from "../../../Service/Pincode.srvice";
import { userAdd, userUpdate, userDetails } from "../../../Service/User.service";
import { notificationSuccess } from "../../../state/Action/NotificationAction";
import { useDispatch } from "react-redux";
import Notification from "../Notification/Notification";

const Add_user = ({ handleClose, modalOpen, handelClick, handleChange, status }) => {
  const dispatch = useDispatch();
  const [showNotification, setShowNotification] = useState({
    show: false,
    type: '',
    message: ''
  });

  const formik = useFormik({
    initialValues: userInitialValues,
    validationSchema: userValidationSchema,
    onSubmit: async (values) => {
      if (values.state == "") {
        setShowNotification({
          show: true,
          type: 'error',
          message: 'Zip code is invalid.',
        });
        return false;
      }
      const payload = {
        "firstName": values.firstName,
        "lastName": values.lastName,
        "email": values.email,
        "mobile": values.mobile,
        "address1": values.address1,
        "address2": values.address2,
        "state": values.state,
        "city": values.city,
        "zipcode": values.zipCode,
      }

      const res = modalOpen.isEdit ? await userUpdate(modalOpen.id, payload) : await userAdd(payload);
      if (res?.status) {
        handleClose();
        dispatch(notificationSuccess({
          show: true,
          type: 'success',
          message: res.message || 'User added successfully.',
        }));

        formik.resetForm();

      } else {
        // handleClose();
        setShowNotification({
          show: true,
          type: 'error',
          message: res.message || 'Something went wrong.',
        });
      }
    },
  }
  );


  const getAddressDetails = async (pin) => {
    const res = await getPincodeList(pin);

    if (res.data.status == 'OK') {
      const location = res.data.results[0].address_components;
      const l1 = res.data.results[0]

      formik.setFieldValue('city', location.find(component => component.types.includes('locality')).long_name);

      formik.setFieldValue('state', location.find(component => component.types.includes('administrative_area_level_1')).long_name);


    } else {
      formik.setFieldValue('city', '');
      formik.setFieldValue('state', '');

    }
  }

  useEffect(() => {
    if (formik.values.zipCode != "" && formik.values.zipCode != null && formik.values.zipCode.length == 6) {
      getAddressDetails(formik.values.zipCode);
    }
  }, [formik.values.zipCode]);

  const getUserDetails = async (id) => {
    const res = await userDetails(id);
    if (res?.status) {
      formik.setValues({
        firstName: res.data.firstName,
        lastName: res.data.lastName,
        email: res.data.email,
        mobile: res.data.mobile,
        address1: res.data.address1,
        address2: res.data.address2,
        zipCode: res.data.zipcode,
        state: res.data.state,
        city: res.data.city,
      });
    }
  }


  useEffect(() => {
    if (modalOpen && modalOpen.isEdit && modalOpen.id) {
      getUserDetails(modalOpen.id);
    }
  }
    , [modalOpen]);


  return (
    <>
      <DialogTitle sx={{ m: 0, p: 2 }} id="Common_modal">
        {modalOpen.isEdit ? "Edit User" : "Add User"}
      </DialogTitle>
      <IconButton
        aria-label="close"
        onClick={handleClose}
        sx={{
          position: "absolute",
          right: 8,
          top: 8,
          color: (theme) => theme.palette.grey[500],
        }}
      >
        <CloseIcon />
      </IconButton>
      <DialogContent>
        <Grid container spacing={2} maxWidth={{ sm: "900px" }}>
          <Grid item xs={6} sm={6}>
            <Input
              labelinput={"First Name"}
              type="text"
              maxLength={100}
              formik={formik}
              name="firstName"
            />
          </Grid>
          <Grid item xs={6} sm={6}>
            <Input
              labelinput={"Last Name"}
              type="text"
              maxLength={100}
              formik={formik}
              name="lastName"
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <Input
              labelinput={"Email"}
              type="text"
              maxLength={50}
              formik={formik}
              name="email"
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <Input
              labelinput={"Contact"}
              type="text"
              maxLength={50}
              formik={formik}
              name="mobile"
            />
          </Grid>
          <Grid item xs={12} sm={12}>
            <Input
              labelinput={"Address Line 1"}
              placeholder={"Address Line 1"}
              name="address1"
              formik={formik}
            />
          </Grid>
          <Grid item xs={12} sm={12}>
            <Input
              labelinput={"Address Line 2"}
              name="address2"
              placeholder={"Address Line 2"}
              formik={formik}
            />
          </Grid>
          <Grid item xs={4} sm={4}>
            <Input
              labelinput={"Zip Code"}
              name="zipCode"
              placeholder={"Zip Code"}
              maxLength={6}
              formik={formik}
            />
          </Grid>
          <Grid item xs={4} sm={4}>
            <Input labelinput={"State"} name="state" placeholder={"State"} formik={formik} disabled />
          </Grid>
          <Grid item xs={4} sm={4}>
            <Input labelinput={"City"} name="city" placeholder={"City"} formik={formik} disabled />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button buttonName="Cancle" size="small" color='tertiary' onClick={handleClose} />
        <Button buttonName={modalOpen.isEdit ? "Save" : "Add"}
          size="small" color='primary' onClick={formik.handleSubmit} />
      </DialogActions>
      <Notification show={showNotification} setShow={setShowNotification} />

    </>
  );
};

export default Add_user;
