import React, { useEffect, useState } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  TextField,
  Select,
  MenuItem,
  Stack,
  Box,
  FormControl,
  InputAdornment,
  Typography,
  Switch,
  Grid,
  Collapse,
} from "@mui/material";
import { MdBlock, MdDelete, MdEdit, MdSearch } from "react-icons/md";
import { IoMdArrowDropdown, IoMdArrowDropup } from "react-icons/io";
import { Button, Heading, Modal } from "../../components/Common";
import { AccountCircle, Height } from "@mui/icons-material";
import { VscFilter } from "react-icons/vsc";
import { userList, userDelete, userStatusChange, userDetails } from "../../Service/User.service";
import { notificationSuccess } from "../../state/Action/NotificationAction";
import { useDispatch } from "react-redux";
import { userilterInitialValues } from "../../helper/initialValues";
import { useFormik } from "formik";
import { Pagination } from "../../components/Common/Table";
import TableRowsLoader from "../../components/Loader/Skeleton";


const UserManagement = () => {
  const [isModalOpen, setIsModalOpen] = useState({
    open: false,
    currentComponent: "",
    para: "",
    head: "",
  });
  const [users, setUsers] = useState([]);
  const [search, setSearch] = useState("");
  const [showFilter, setShowFilter] = useState(false);
  const [page, setPage] = React.useState(0);
  const [totalData, setTotalData] = useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [sortConfigUser, setSortConfigUser] = useState({ key: "", direction: "" });
  const [userDetailsPage, setUserDetailPage] = useState({});
  const [sortConfig, setSortConfig] = useState({
    key: "name",
    direction: "asc",
  });

  const dispatch = useDispatch();

  const changeStatus = async () => {
    let res =
      isModalOpen && isModalOpen?.currentComponent == "status"
        ? await userStatusChange(isModalOpen?.id)
        : isModalOpen && isModalOpen?.currentComponent == "delete"
          ? await userDelete(isModalOpen?.id)
          : null;
    if (res?.status) {
      dispatch(
        notificationSuccess({
          show: true,
          type: "success",
          message: res.message || "Success",
        })
      );
      setIsModalOpen({ open: false, currentComponent: "" });
      getUsersData();
    } else {
      dispatch(
        notificationSuccess({
          show: true,
          type: "error",
          message:
            res.message || "Something went wrong. Please try again later.",
        })
      );
    }
  };
  useEffect(() => {
    getUsersData();
  }, [search, page, sortConfigUser]);

  const getUsersData = async () => {
    setUsers([]);
    let selectQuery = `?search=${search}&status=${formik.values.status}&orderBy=${sortConfigUser.key}&sortFlag=${sortConfigUser.direction == 'asc' ? true : false}&page=${page + 1}`;
    let response = await userList(selectQuery)
    if (response?.status) {
      setUsers(response?.data);
      setTotalData(response.total);
    }
    else {
      setUsers(null);
    }
  }

  const getUserDetails = async (id) => {
    const res = await userDetails(id);
    if (res?.status) {
      setUserDetailPage(res.data);
      setIsModalOpen({
        open: true,
        currentComponent: "UserDetails",
      });
    }
  }

  const formik = useFormik({
    initialValues: userilterInitialValues,
    onSubmit: async (values) => {
      getUsersData();
    },
  });

  const handleFilterClick = () => {
    setShowFilter(!showFilter);
  };

  return (
    <>
      <Stack spacing={2}>
        <Heading head={"User Management"} />

        {/* Search and Filter */}
        <Stack
          mb={2}
          flexDirection={"row"}
          justifyContent={"space-between"}
          flexWrap={{ xs: "wrap", sm: "nowrap" }}
          gap={2}
        >
          <FormControl sx={{ width: { xs: "100%", md: "500px" } }}>
            <Typography fontSize={"14px"}>Search</Typography>
            <TextField
              placeholder="Search Users"
              variant="outlined"
              value={search}
              onChange={(e) => {
                setSearch(e.target.value)
                setPage(0);
              }}
              style={{ marginRight: "10px" }}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <MdSearch />
                  </InputAdornment>
                ),
              }}
            />
          </FormControl>

          <Stack
            flexDirection={"row"}
            alignItems={"end"}
            gap={{ xs: 1, sm: 2 }}
            flexWrap={"wrap"}
            width={{ xs: "100%", sm: "auto" }}
          >

            <Stack mt={3} gap={1} display={"flex"} flexDirection={"row"}>

              <Button
                variant="contained"
                color="primary"
                buttonName="Add New User"
                onClick={() => {
                  setIsModalOpen({
                    open: true,
                    currentComponent: "AddUser",
                  });
                }}
              />
              <Button
                buttonName={<VscFilter />}
                style={{
                  padding: "10px 11px",
                  backgroundColor: "secondary.main",
                }}
                color="white"
                size="small"
                variant="contained"
                onClick={handleFilterClick}
              />
            </Stack>
          </Stack>
        </Stack>
        <Collapse in={showFilter}>
          <Stack
            sx={{ backgroundColor: "white.main" }}
            flexDirection={"row "}
            gap={2}
            flexWrap={"wrap"}
            justifyContent={"flex-end"}
            p={1.5}
            alignItems={"center"}
          >
            <Grid className="filterinput" style={{ marginTop: "-10px" }}>
              <Typography>{"Status"}</Typography>
              <Select
                fullWidth
                onChange={(e) => {
                  formik.setFieldValue("status", e.target.value);
                }}
                displayEmpty
                value={formik.values.status}
              >
                <MenuItem value="">Select Status</MenuItem>
                <MenuItem value="1">Active</MenuItem>
                <MenuItem value="0">In Active</MenuItem>
              </Select>
            </Grid>

            <Stack gap={1} flexDirection={"row"}>
              <Box>
                <Button
                  color="primary"
                  buttonName={"Filter"}
                  onClick={formik.handleSubmit}
                />
              </Box>
              <Box>
                <Button
                  buttonName={"Clear"}
                  color="tertiary"
                  onClick={() => {
                    setShowFilter(false);
                    formik.resetForm();
                    formik.handleSubmit();
                  }}
                />
              </Box>
            </Stack>
          </Stack>
        </Collapse>

        {/* User Table */}
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell onClick={() => setSortConfigUser({ key: "name", direction: sortConfigUser && sortConfigUser.key === "name" && sortConfigUser.direction === "asc" ? "desc" : "asc" })}>
                  Name{" "}
                  {sortConfigUser && sortConfigUser.key === "name" && sortConfigUser.direction === "asc" ? <IoMdArrowDropup /> : <IoMdArrowDropdown />}
                </TableCell>
                <TableCell>Email</TableCell>
                <TableCell>Contact</TableCell>
                <TableCell >Status{" "}</TableCell>
                <TableCell>Actions</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {users?.map((user) => (
                <TableRow key={user.id} onClick={() => getUserDetails(user.id)}>
                  <TableCell>{user?.firstName} {user?.lastName}</TableCell>
                  <TableCell>{user.email}</TableCell>
                  <TableCell>{user.mobile}</TableCell>
                  <TableCell>
                    <Switch
                      checked={user?.status === 1 ? true : false}
                      disabled={user?.status == 2 ? true : false}
                      onClick={(e) => {
                        e.stopPropagation();
                        setIsModalOpen({
                          id: user?.id,
                          open: true,
                          currentComponent: "status",
                          head: "Change Status",
                          para: "Are you sure you want to Change the Status?",
                        });
                      }}
                    />
                  </TableCell>
                  <TableCell onClick={(e) => e.stopPropagation()}>
                    <FormControl>
                      <Select
                        defaultValue=""
                        displayEmpty
                        variant="outlined"
                        value=""
                        onChange={(e) => {
                          e.stopPropagation();

                          if (e.target.value === "edit") {
                            setIsModalOpen({
                              open: true,
                              currentComponent: "AddUser",
                              head: "Edit Agency",
                              para: "Are you sure you want to Edit this Agency?",
                              id: user.id,
                              isEdit: true
                            })
                          } else if (e.target.value === "delete") {
                            setIsModalOpen({
                              open: true,
                              currentComponent: "delete",
                              head: "Delete Agency",
                              para: "Are you sure you want to Delete this Agency?",
                              id: user.id,
                            })
                          }
                        }}

                      >
                        <MenuItem value="" disabled hidden>
                          Select Action
                        </MenuItem>
                        <MenuItem value="edit">
                          <MdEdit color="primary" />
                          &nbsp;&nbsp; Edit
                        </MenuItem>

                        <MenuItem value="delete" >
                          <MdDelete color="error" />
                          &nbsp;&nbsp; Delete
                        </MenuItem>
                      </Select>
                    </FormControl>
                  </TableCell>
                </TableRow>
              ))}
              {users === null ? (
                <TableRow>
                  <TableCell
                    sx={{
                      color: "silver",
                      textAlign: "center",
                      paddingTop: "90px",
                      borderBottom: "none",
                      fontSize: "30px",
                    }}
                    colSpan="6"
                  >
                    No records to display
                  </TableCell>
                </TableRow>
              ) : users && users.length === 0 ? (
                <TableRowsLoader rowsNum={10} colNumber={5} />
              ) : null}
            </TableBody>
          </Table>
        </TableContainer>
        {users != null && (
          <Pagination
            totalData={totalData}
            page={page}
            setPage={setPage}
            rowsPerPage={rowsPerPage}
            setRowsPerPage={setRowsPerPage}
          />
        )}

      </Stack >
      <Modal
        modalOpen={isModalOpen}
        handleClose={() => {
          setIsModalOpen({ open: false, currentComponent: "" })
          getUsersData();
        }
        }
        handelClick={changeStatus}
        data={userDetailsPage}

      />
    </>
  );
};

export default UserManagement;
