import React, { useEffect, useState } from "react";
import {
  Autocomplete,
  Box,
  Checkbox,
  CircularProgress,
  Grid,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";
import Logo from "../../assets/images/Login-logo.png";
import { Button, Input } from "../../components/Common";
import { notificationSuccess } from "../../state/Action/NotificationAction";
import { useFormik } from "formik";
import { getPincodeList } from "../../Service/Pincode.srvice";
import { userRegister } from "../../Service/User.service";
import { agencyRegisterInitialValues, userRegisterInitialValues } from "../../helper/initialValues";
import { agencyRegisterValidationSchema, userRegisterValidationSchema } from "../../helper/validation";
import { useDispatch } from "react-redux";
import { loginUserApi } from "../../state/Action/authAction";
import Cookies from "universal-cookie";
import { serviceDropdown } from "../../Service/ServiceDepartment.service";
import { agencyRegister } from "../../Service/Agency.service";

const AgencyRegister = ({ onLoginClick, setIsRegister }) => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [serviceData, setServiceData] = useState([]);
  const dispatch = useDispatch();
  const { state } = useLocation();
  const [paymentFlow, setPaymentFlow] = useState(false);

  const cookies = new Cookies();

  useEffect(() => {
    getServiceData();
  }, []);

  useEffect(() => {
    console.log("statedsdsdsds", state);
    if (state?.from === 1) {
      setPaymentFlow(true);
    }
  }, [state]);

  const getServiceData = async () => {
    const response = await serviceDropdown();
    if (response?.status) {
      setServiceData(response?.data);
    } else {
      setServiceData([]);
    }
  };
  const formik = useFormik({
    initialValues: agencyRegisterInitialValues,
    validationSchema: agencyRegisterValidationSchema,
    onSubmit: async (values) => {
      if (values.state == "") {
        dispatch(notificationSuccess({
          show: true,
          type: 'error',
          message: 'Zip code is invalid.',
        }));
        return false;
      }
      const payload = {
        "agencyName": values.agencyName,
        "email": values.email,
        "mobile": values.mobile,
        "password": values.password,
        "address1": values.address1,
        "address2": values.address2,
        "state": values.state,
        "city": values.city,
        "zipcode": values.zipCode,
        "agencyService": values.servideIds
      }

      const res = await agencyRegister(payload);
      if (res?.status) {

        if (paymentFlow && res?.data?.role != 2) {
          dispatch(notificationSuccess({
            show: true,
            type: 'error',
            message: 'Invalid Credentials.'
          }
          ))
          return;
        }
        if (paymentFlow && res?.data?.role == 2) {
          navigate("/check-out", { state: { cartId: state?.cartId } });
          return;
        }




        cookies.set('token', res?.data?.token, { path: '/' });
        localStorage.setItem('token', res?.data?.token);
        setIsRegister(0);
        dispatch(loginUserApi(res?.data));
        dispatch(notificationSuccess({
          show: true,
          type: 'success',
          message: res.message || 'User added successfully.',
        }));

        formik.resetForm();
        if (!paymentFlow && res?.data?.role == 2) {
          navigate("/dashboard");
        }

      } else {
        dispatch(notificationSuccess({
          show: true,
          type: 'error',
          message: res.message || 'Something went wrong.',
        }));
      }
    },
  }
  );

  console.log("sasasasasasasa", formik.errors);


  const getAddressDetails = async (pin) => {
    const res = await getPincodeList(pin);

    if (res.data.status == 'OK') {
      const location = res.data.results[0].address_components;
      const l1 = res.data.results[0]

      formik.setFieldValue('city', location.find(component => component.types.includes('locality')).long_name);

      formik.setFieldValue('state', location.find(component => component.types.includes('administrative_area_level_1')).long_name);


    } else {
      formik.setFieldValue('city', '');
      formik.setFieldValue('state', '');

    }
  }

  useEffect(() => {
    if (formik.values.zipCode != "" && formik.values.zipCode != null && formik.values.zipCode.length == 6) {
      getAddressDetails(formik.values.zipCode);
    }
  }, [formik.values.zipCode]);

  return (
    <Box
      sx={{
        width: "100%",
        MaxHeight: "100vh",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        background: "linear-gradient(135deg, #ece9e6 0%, #ffffff 100%)",
        padding: "20px 0px",
      }}
    >
      <Stack
        alignItems="center"
        justifyContent="center"
        sx={{
          width: { xs: "94%", sm: "530px" },
          padding: "40px",
          borderRadius: "16px",
          backgroundColor: "#fbfbfbfb",
          boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.1)",
          transition: "box-shadow 0.3s ease-in-out",
          "&:hover": {
            boxShadow: "0px 8px 16px rgba(0, 0, 0, 0.15)",
          },
        }}
      >
        <img src={Logo} alt="logo" width={170} />
        <Typography
          fontWeight={700}
          fontSize="20px"
          textAlign="center"
          mb={3}
          mt={2}
          color="primary"
        >
          Register Your Account
        </Typography>

        <Stack gap={2} width="100%">
          <Grid container spacing={2} maxWidth={{ sm: "900px" }}>
            <Grid item xs={12} sm={6}>
              <Input
                labelinput="Agency Name"
                placeholder="Agency Name"
                name="agencyName"
                maxLength={50}
                disabled={loading}
                fullWidth
                formik={formik}

              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <Input
                labelinput="Contact Number"
                placeholder="Contact Number"
                name="mobile"
                maxLength={15}
                disabled={loading}
                fullWidth
                formik={formik}

              />
            </Grid>

            <Grid item xs={12} sm={12}>
              <Input
                labelinput="Email Address"
                placeholder="Email"
                name="email"
                maxLength={50}
                disabled={loading}
                fullWidth
                formik={formik}

              />
            </Grid>
            <Grid item xs={12} sm={12}>
              <Input
                labelinput="Password"
                placeholder="Password"
                name="password"
                maxLength={50}
                disabled={loading}
                passwordinput
                formik={formik}

                fullWidth
              />
            </Grid>
            <Grid item xs={12} sm={12}>
              <Input
                labelinput={"Address Line 1"}
                placeholder={"Address Line 1"}
                name="address1"
                formik={formik}
              />
            </Grid>
            <Grid item xs={12} sm={12}>
              <Input
                labelinput={"Address Line 2"}
                name="address2"
                placeholder={"Address Line 2"}
                formik={formik}
              />
            </Grid>
            <Grid item xs={4} sm={4}>
              <Input
                labelinput={"Zip Code"}
                name="zipCode"
                placeholder={"Zip Code"}
                maxLength={6}
                formik={formik}
              />
            </Grid>
            <Grid item xs={4} sm={4}>
              <Input labelinput={"State"} name="state" placeholder={"State"} formik={formik} disabled />
            </Grid>
            <Grid item xs={4} sm={4}>
              <Input labelinput={"City"} name="city" placeholder={"City"} formik={formik} disabled />
            </Grid>
            <Grid item xs={12}>
              <Typography
                fontSize={{ xs: "10px", sm: "13px" }}
                fontWeight={500}
                mb={"2px"}
              >
                Select Service Type
              </Typography>

              <Autocomplete
                multiple
                options={serviceData.map((option) => option.serviceName)}
                value={formik.values.services}
                onChange={(event, newValue) => {
                  formik.setFieldValue('servideIds', serviceData
                    .filter(service => newValue.includes(service.serviceName)) // Filter based on selected service names
                    .map(service => ({ serviceId: service.id })) // Map to get objects in the desired format
                  );
                  formik.setFieldValue('services', newValue);
                }}
                renderInput={(params) => <TextField {...params} />}
              />
            </Grid>
          </Grid>

          <Stack
            flexDirection="row"
            justifyContent="start"
            alignItems="center"
            mt={1}
          >
            <Checkbox
              defaultChecked
              disabled={loading}
              name="terms"
              color="primary"
              sx={{ padding: 0, "& .MuiSvgIcon-root": { fontSize: 20 } }}
            />
            <Typography fontSize="14px" color="text.secondary">
              I agree to the terms and conditions
            </Typography>
          </Stack>

          <Button
            buttonName={
              loading ? (
                <React.Fragment>
                  &nbsp;&nbsp;&nbsp;
                  <CircularProgress size={18} color="inherit" />
                  &nbsp;&nbsp;&nbsp;
                </React.Fragment>
              ) : (
                "Register"
              )
            }
            onClick={formik.handleSubmit}
            disabled={loading}
            fullWidth
            variant="contained"
            color="primary"
            sx={{
              padding: "12px",
              fontWeight: "bold",
              borderRadius: "8px",
              fontSize: "16px",
            }}
          />
          <Typography
            fontSize="14px"
            color="primary"
            sx={{ cursor: "pointer", textAlign: "center", mt: 2 }}
            onClick={() => {
              setIsRegister(0);
            }}          >
            Already have an account? Log In
          </Typography>
        </Stack>
      </Stack>
    </Box>
  );
};

export default AgencyRegister;
