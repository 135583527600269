import React, { useState } from "react";
import {
  Container,
  TextField,
  Grid,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Typography,
  Card,
  CardContent,
  Stack,
  Box,
} from "@mui/material";
import { Heading, Input } from "../../components/Common";

const hospitals = [
  { label: "City Hospital", id: 1 },
  { label: "Green Valley Clinic", id: 2 },
];

const doctors = {
  1: [
    {
      name: "Dr. John Doe",
      id: 1,
      slots: ["09:00 AM", "10:00 AM", "11:00 AM","09:00 AM", "10:00 AM", "11:00 AM","09:00 AM", "10:00 AM", "11:00 AM",],
      booked: ["10:00 AM"], // This slot is booked

    },
    {
      name: "Dr. Sarah Smith",
      id: 2,
      slots: ["12:00 PM", "01:00 PM", "02:00 PM"],
      booked: [], // No slots booked
    },
    {
      name: "Dr. Michael Johnson",
      id: 3,
      slots: ["03:00 PM", "04:00 PM", "05:00 PM", "06:00 PM"],
      booked: ["03:00 PM", "05:00 PM"], // These slots are booked
    },
  ],
  2: [
    {
      name: "Dr. Alice Johnson",
      id: 3,
      slots: ["09:30 AM", "10:30 AM", "11:30 AM"],
      booked: ["09:30 AM"], // This slot is booked
    },
    {
      name: "Dr. Mark Brown",
      id: 4,
      slots: ["01:00 PM", "02:00 PM", "03:00 PM"],
      booked: [], // No slots booked
    },
  ],
};

const appointmentTypes = ["In-Person", "Virtual"];

const Appointment_Slot_booking = () => {
  const [selectedHospital, setSelectedHospital] = useState(""); // This will store the hospital ID
  const [selectedDate, setSelectedDate] = useState("");
  const [appointmentType, setAppointmentType] = useState("");
  const [selectedSlot, setSelectedSlot] = useState(null);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [patientDetails, setPatientDetails] = useState({
    name: "",
    age: "",
    contact: "",
  });

  // Use the hospital ID to filter doctors
  const filteredDoctors = selectedHospital ? doctors[selectedHospital] : [];

  const handleSelectSlot = (doctor, slot) => {
    setSelectedSlot(slot);
    setDialogOpen(true);
  };

  const handlePatientSubmit = () => {
    console.log("Selected Hospital:", selectedHospital);
    console.log("Selected Date:", selectedDate);
    console.log("Appointment Type:", appointmentType);
    console.log("Selected Slot:", selectedSlot);
    console.log("Patient details:", patientDetails);
    setDialogOpen(false);
  };

  return (
    <>
      <Stack gap={2}>
        <Heading head={"Appointment Booking"} />

        <Grid container spacing={3}>
          {/* Hospital Search */}
          <Grid item xs={12} md={4}>
            <FormControl fullWidth>
              <Typography>Select Hospital</Typography>
              <Select
                value={selectedHospital}
                onChange={(e) => {
                  const selected = e.target.value;
                  setSelectedHospital(selected);
                  setAppointmentType("");
                }}
              >
                <MenuItem value="">
                  <em>Select Hospital</em>
                </MenuItem>
                {hospitals.map((hospital) => (
                  <MenuItem key={hospital.id} value={hospital.id}>
                    {hospital.label}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>

          {/* Date Picker */}
          <Grid item xs={12} md={4}>
            <Typography>Select Date</Typography>
            <TextField
              type="date"
              InputLabelProps={{ shrink: true }}
              fullWidth
              onChange={(e) => setSelectedDate(e.target.value)}
            />
          </Grid>

          {/* Type of Appointment */}
          <Grid item xs={12} md={4}>
            <Typography>Type of Appointment</Typography>
            <FormControl fullWidth>
              <Select
                value={appointmentType}
                onChange={(e) => setAppointmentType(e.target.value)}
              >
                <MenuItem value="">
                  <em>Type of Appointment</em>
                </MenuItem>
                {appointmentTypes.map((type) => (
                  <MenuItem key={type} value={type}>
                    {type}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
        </Grid>

        {/* List of Doctors and Available Time Slots */}
        {appointmentType && filteredDoctors.length > 0 && (
          <Box mt={3}>
            <Heading smallHead={"Available Doctors List"} />
            <Grid container spacing={3}>
              {filteredDoctors.map((doctor) => (
                <Grid item xs={12} md={6} key={doctor.id}>
                  <Card>
                    <CardContent>
                      <Typography variant="h6">{doctor.name}</Typography>
                      <Typography variant="body2" color="textSecondary">
                        Available Slots:
                      </Typography>
                      <Grid container spacing={2} marginTop={1}>
                        {doctor.slots.map((slot) => (
                          <Grid item key={slot}>
                            <Button
                              variant="outlined"
                              onClick={() => handleSelectSlot(doctor, slot)}
                              disabled={doctor.booked.includes(slot)} // Disable if the slot is booked
                            >
                              {slot}
                            </Button>
                          </Grid>
                        ))}
                      </Grid>
                    </CardContent>
                  </Card>
                </Grid>   
              ))}
            </Grid>
          </Box>
        )}
      </Stack>

      {/* Patient Details Dialog */}
      <Dialog open={dialogOpen} onClose={() => setDialogOpen(false)}>
        <DialogTitle>Enter Patient Details</DialogTitle>
        <DialogContent sx={{ width: "350px" }}>
          <Input
            labelinput="Name"
            margin="dense"
            onChange={(e) =>
              setPatientDetails({ ...patientDetails, name: e.target.value })
            }
          />
          <Input
            labelinput="Age"
            margin="dense"
            onChange={(e) =>
              setPatientDetails({ ...patientDetails, age: e.target.value })
            }
          />
          <Input
            labelinput="Contact Number"
            margin="dense"
            onChange={(e) =>
              setPatientDetails({ ...patientDetails, contact: e.target.value })
            }
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setDialogOpen(false)}>Cancel</Button>
          <Button onClick={handlePatientSubmit}>Submit</Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default Appointment_Slot_booking;
