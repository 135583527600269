import {
  Box,
  Chip,
  FormControl,
  IconButton,
  InputAdornment,
  Paper,
  Stack,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import { MdDelete, MdEdit, MdSearch } from "react-icons/md";
import { Button, Heading, Modal } from "../../../components/Common";

const data = [
  {
    position: 1,
    from: "Helth Care",
    to: "Pathway Counseling Services",
    status: "Pending",
  },
  {
    position: 2,
    from: "Wellness Center for Kids",
    to: "Chicago, IL",
    status: "Pending",
  },
  {
    position: 3,
    from: "Pathway Counseling Services",
    to: "Bright Future Therapy",
    status: "Accepted",
  },
];
const Referral_Update = () => {
  const [isModalOpen, setIsModalOpen] = useState({
    open: false,
    currentComponent: "",
    para: "",
    head: "",
  });
  const [searchTerm, setSearchTerm] = useState("");
  return (
    <>
      <Stack gap={2}>
        <Heading head={"Referral Update"} />
     
          <Box width={'100%'}>
            <FormControl sx={{ width: { xs: "100%", sm: "500px" } }}>
              <Typography fontSize={"14px"}>Search</Typography>
              <TextField
                placeholder="Search "
                variant="outlined"
                style={{ marginRight: "10px" }}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <MdSearch />
                    </InputAdornment>
                  ),
                }}
              />
            </FormControl>
          </Box>
      
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>position</TableCell>
                <TableCell>Refer By</TableCell>
                <TableCell>Refer To</TableCell>
                <TableCell>Status</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {data.map((row) => (
                <TableRow key={row.position}>
                  <TableCell>{row.position}</TableCell>
                  <TableCell>{row.from}</TableCell>
                  <TableCell>{row.to}</TableCell>
                  <TableCell>
                    <Chip
                      label={row.status}
                      color={
                        row.status === "Pending" ? "primary" : "secondary"
                      }
                    />
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Stack>
      <Modal
        modalOpen={isModalOpen}
        handleClose={() =>
          setIsModalOpen({ open: false, currentComponent: "" })
        }
      />
    </>
  );
};

export default Referral_Update;
