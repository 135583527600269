import React, { useEffect, useState } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel,
  TextField,
  MenuItem,
  Select,
  Paper,
  Stack,
  Typography,
  Box,
  InputAdornment,
  FormControl,
  Tooltip,
  IconButton,
  Chip,
  Collapse,
  Grid,
  Switch,
  Autocomplete,
} from "@mui/material";
import AgencyDetailView from "./AgencyDetailView"; // Import the detail view component
import { Button, Heading, Input, Modal } from "../../components/Common";
import { MdPin, MdPinDrop, MdSearch } from "react-icons/md";
import { VscFilter } from "react-icons/vsc";
import { BsEye } from "react-icons/bs";
import { useNavigate } from "react-router-dom";
import { agencyList } from "../../Service/Agency.service";

const countries = [
  { pincode: "10001" },
  { pincode: "25062" },
  { pincode: "10003" },
  { pincode: "30268" },
  { pincode: "90658" },
  { pincode: "63648" },
  { pincode: "10507" },
  { pincode: "10508" },
];

const uniqueLocations = [
  "Los Angeles",
  "Houston",
  "Chicago",
  "Miami",
  "New York",
  "Phoenix",
];
const uniqueStates = ["California", "Texas", "Illinois", "Florida", "New York"];
const uniqueServiceTypes = [
  "ABA Therapy",
  "OT Services",
  "Evaluation",
  "Social Skills",
  "SLP Services",
  "Case Management",
];

const Agency_Management = () => {
  const [filter, setFilter] = useState({
    location: "",
    serviceCategory: "",
    availabilityStatus: "",
  });
  const [selectedAgency, setSelectedAgency] = useState(null);
  const [agencyData, setAgencyData] = useState([]);
  const [isDetailOpen, setIsDetailOpen] = useState(false);
  const [search, setSearch] = useState("");
  const [select, setselect] = useState("");
  const [isModalOpen, setIsModalOpen] = useState({
    open: false,
    currentComponent: "",
    para: "",
    head: "",
  });
  const [zipcodeFilter, setZipcodeFilter] = useState([]);
  const [locationFilter, setLocationFilter] = useState([]);
  const [stateFilter, setStateFilter] = useState([]);
  const [serviceTypeFilter, setServiceTypeFilter] = useState([]);
  const [availabilityFilter, setAvailabilityFilter] = useState([]);
  const uniqueAvailabilities = ["Available", "Unavailable", "Limited"];
  const resetFilters = () => {
    setLocationFilter([]);
    setStateFilter([]);
    setServiceTypeFilter([]);
    setAvailabilityFilter([]);
    setZipcodeFilter([]);
  };
  const handleFilterChange = (event) => {
    setFilter({ ...filter, [event.target.name]: event.target.value });
  };

  const handleViewDetails = (agency) => {
    setSelectedAgency(agency);
    setIsDetailOpen(true);
  };

  const handleCloseDetail = () => {
    setIsDetailOpen(false);
    setSelectedAgency(null);
  };

  useEffect(() => {
    getAgencyData();
  }, [search]);

  const getAgencyData = async () => {
    setAgencyData([]);
    const response = await agencyList(search);
    if (response?.status) {
      setAgencyData(response?.data);
    } else {
      setAgencyData(null);
    }
  };

  const agencies = [
    {
      id: 1,
      name: "Bright Future Therapy",
      location: "Los Angeles, CA",
      serviceCategory: "ABA Therapy",
      availabilityStatus: "Available",
      purchaseplan: "Monthly",
      expirein: "30 days",
    },
    {
      id: 2,
      name: "Pathway Counseling Services",
      location: "Houston, TX",
      serviceCategory: "OT Services",
      availabilityStatus: "Unavailable",
      purchaseplan: "Yearly",
      expirein: "265 days",
    },
    {
      id: 3,
      name: "Wellness Center for Kids",
      location: "Chicago, IL",
      serviceCategory: "Evaluation",
      availabilityStatus: "Available",
      purchaseplan: "Monthly",
      expirein: "13 days",
    },
    {
      id: 4,
      name: "Family First Home Care",
      location: "Miami, FL",
      serviceCategory: "SLP Services",
      availabilityStatus: "Unavailable",
      purchaseplan: "Yearly",
      expirein: "235 days",
    },
    {
      id: 5,
      name: "Hope and Healing Clinic",
      location: "New York, NY",
      serviceCategory: "Case Management",
      availabilityStatus: "Available",
      purchaseplan: "Monthly",
      expirein: "30 days",
    },
  ];
  const navigate = useNavigate();
  const [showFilter, setShowFilter] = useState(false);
  const handleFilterClick = () => {
    setShowFilter(!showFilter);
  };
  const handleSelect = (event) => {
    setselect(event.target.value);
  };

  return (
    <>
      <Stack spacing={2}>
        <Heading head={"Registered Agencies"} />

        <Box
          sx={{
            marginBottom: 4, // 16px
            display: "flex",
            flexDirection: { xs: "column", sm: "row" },
            justifyContent: "space-between",
            alignItems: "flex-start",
            flexWrap:"wrap",
            gap: 2,
            width: "100%",
          }}
        >
          {/* Search Location */}
          <FormControl sx={{ width: { xs: "100%", sm: "500px" } }}>
            <Typography>Search Location</Typography>
            <TextField
              placeholder="Search Location"
              name="location"
              value={filter.location}
              onChange={handleFilterChange}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <MdPinDrop />
                  </InputAdornment>
                ),
              }}
              fullWidth
            />
          </FormControl>

          {/* Service Category and Availability Filters */}
        
            {/* Service Category */}
            {/* <Box sx={{ width: { xs: "100%", sm: "250px" } }}>
              <Typography>Service Category</Typography>
              <Select
                name="serviceCategory"
                value={filter.serviceCategory}
                onChange={handleFilterChange}
                fullWidth
              >
                <MenuItem value="Service 1">ABA Therapy</MenuItem>
                <MenuItem value="Service 2">OT Services</MenuItem>
                <MenuItem value="Service 3">Evaluation</MenuItem>
                <MenuItem value="Service 4">Social Skills</MenuItem>
                <MenuItem value="Service 5">SLP Services</MenuItem>
                <MenuItem value="Service 6">Case Management</MenuItem>
              </Select>
            </Box> */}

            <Box
              display={"flex"}
              flexDirection={"row"}
              flexWrap={"wrap"}
              alignItems={"end"}
              gap={1}
            >
             
              {/* City Autocomplete */}
              <FormControl style={{ minWidth: 250 }}>
                <Typography>City</Typography>
                <Autocomplete
                  multiple
                  options={uniqueLocations}
                  value={locationFilter}
                  onChange={(event, newValue) => setLocationFilter(newValue)}
                  renderInput={(params) => <TextField {...params} />}
                />
              </FormControl>
              {/* State Autocomplete */}
              <FormControl style={{ minWidth: 250 }}>
                <Typography>State</Typography>
                <Autocomplete
                  multiple
                  options={uniqueStates}
                  value={stateFilter}
                  onChange={(event, newValue) => setStateFilter(newValue)}
                  renderInput={(params) => <TextField {...params} />}
                />
              </FormControl>
               {/* ZipCode Autocomplete (unchanged) */}
               <FormControl style={{ minWidth: 250 }}>
                <Typography>ZipCode</Typography>
                <Autocomplete
                  multiple
                  id="zipcode-autocomplete"
                  options={countries}
                  getOptionLabel={(option) => option.pincode}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      InputProps={{
                        ...params.InputProps,
                        autoComplete: "zipcode", // disable autocomplete and autofill
                      }}
                    />
                  )}
                  onChange={(event, newValue) => {
                    setZipcodeFilter(newValue.map((option) => option.pincode));
                  }}
                />
              </FormControl>
              {/* Service Type Autocomplete */}
              <FormControl style={{ minWidth: 250 }}>
                <Typography>Service Type</Typography>
                <Autocomplete
                  multiple
                  options={uniqueServiceTypes}
                  value={serviceTypeFilter}
                  onChange={(event, newValue) => setServiceTypeFilter(newValue)}
                  renderInput={(params) => <TextField {...params} />}
                />
              </FormControl>
              {/* Availability Autocomplete */}
              <FormControl style={{ minWidth: 250 }}>
                <Typography>Availability</Typography>
                <Autocomplete
                  multiple
                  options={uniqueAvailabilities}
                  value={availabilityFilter}
                  onChange={(event, newValue) =>
                    setAvailabilityFilter(newValue)
                  }
                  renderInput={(params) => <TextField {...params} />}
                />
              </FormControl>

              <Button
                variant="contained"
                color="primary"
                onClick={resetFilters}
                buttonName={"Clear All"}
              />
            {/* Filter Button */}
            <Box display={"flex"} gap={1}>
              <Tooltip title="Filter">
                <Button
                  variant="contained"
                  buttonName={<VscFilter />}
                  onClick={handleFilterClick}
                  color="white"
                  sx={{ marginTop: "10px" }}
                />
              </Tooltip>
            </Box>
            </Box>

        </Box>
        <Collapse in={showFilter}>
          <Stack
            sx={{ backgroundColor: "white.main" }}
            flexDirection={"row "}
            gap={2}
            flexWrap={"wrap"}
            justifyContent={"flex-end"}
            p={1.5}
            alignItems={"center"}
          >
            <Grid className="filterinput">
              <Input
                placeholder={"Agency Name"}
                type="text"
                labelinput="Agency Name"
              />
            </Grid>
            <Grid className="filterinput">
              <Input
                placeholder={"Location Name"}
                type="text"
                labelinput="Location Name"
              />
            </Grid>
            <Grid className="filterinput" style={{ marginTop: "-10px" }}></Grid>
            <Stack gap={1} flexDirection={"row"}>
              <Box>
                <Button color="primary" buttonName={"Filter"} />
              </Box>
              <Box>
                <Button
                  buttonName={"Clear"}
                  color="tertiary"
                  onClick={() => setShowFilter(false)}
                />
              </Box>
            </Stack>
          </Stack>
        </Collapse>
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Name</TableCell>
                <TableCell>Location</TableCell>
                <TableCell>Purchase Plan</TableCell>
                <TableCell>Expire In </TableCell>
                <TableCell>Availability</TableCell>
                <TableCell>Actions</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {agencies.map((agency) => (
                <TableRow key={agency.id}>
                  <TableCell
                    onClick={() => {
                      navigate("/agency-details");
                    }}
                  >
                    {agency.name}
                  </TableCell>
                  <TableCell
                    onClick={() => {
                      navigate("/agency-details");
                    }}
                  >
                    {agency.location}
                  </TableCell>
                  <TableCell
                    onClick={() => {
                      navigate("/agency-details");
                    }}
                  >
                    {agency.purchaseplan}
                  </TableCell>
                  <TableCell
                    onClick={() => {
                      navigate("/agency-details");
                    }}
                  >
                    {agency.expirein}
                  </TableCell>
                  <TableCell>
                    <Chip
                      label={agency.availabilityStatus}
                      color={agency.availabilityStatus === "Available" ? "secondary" : "primary"}
                    />
                    {/* <Switch /> */}
                  </TableCell>
                  <TableCell>
                    <Tooltip title="View Details">
                      <IconButton
                        sx={{
                          backgroundColor: "primary.main",
                          color: "white.main",
                        }}
                        // onClick={() => handleViewDetails(agency)}
                        onClick={() => {
                          navigate("/agency-details");
                        }}
                      >
                        <BsEye size={15} />
                      </IconButton>
                    </Tooltip>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Stack>

      {/* <AgencyDetailView
        open={isDetailOpen}
        onClose={handleCloseDetail}
        agency={selectedAgency}
      /> */}
      <Modal
        modalOpen={isModalOpen}
        handleClose={() =>
          setIsModalOpen({ open: false, currentComponent: "" })
        }
      />
    </>
  );
};

export default Agency_Management;
