import React, { useEffect, useState } from "react";
import { Box, Grid, IconButton, Modal, Stack, Typography } from "@mui/material";
import { FaRegUser } from "react-icons/fa";
import { MdOutlineModeEditOutline } from "react-icons/md";
import {
  BackButton,
  Button,
  Heading,
  Input,
} from "../../components/Common";
import { CloseIcon } from "../../helper/Icons";
import { AdminDetail, changePassword, AdminUpdate } from "../../Service/Admin.service";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { logoutApi } from "../../state/Action/authAction";
import { changePasswordInitialValues } from "../../helper/initialValues";
import { changePasswordValidationSchema } from "../../helper/validation";
import { notificationSuccess } from "../../state/Action/NotificationAction";
import { useFormik } from "formik";
import Modals from "../../components/Common/Modal";



const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  borderRadius: "10px",
  boxShadow: 24,
  p: 4,
  display: "flex",
  flexDirection: "column",
  gap: 2,
};

const UserProfile = () => {
  const [edit, setEdit] = useState(false);
  const [file1, setfile1] = useState(null);
  const [profile, setProfile] = useState({});
  const [open, setOpen] = React.useState(false);
  const { user } = useSelector((state) => state?.root?.auth);
  const dispatch = useDispatch();
  const Navigate = useNavigate();
  const [isModalOpen, setIsModalOpen] = useState({
    open: false,
    currentComponent: "",
    para: '', head: ''
  });
  const handelLogout = () => {
    dispatch(logoutApi());
    localStorage.removeItem('token');
    Navigate('/');
  }

  const formik = useFormik({
    initialValues: changePasswordInitialValues,
    onSubmit: async (value) => {

      if (value.newPassword !== value.confirmPassword) {
        dispatch(notificationSuccess({
          show: true,
          type: 'error',
          message: 'Password and confirm password does not match.',
        }));
        return;
      }

      const payload = {
        oldPassword: value.oldPassword,
        newPassword: value.newPassword,
      }

      const res = await changePassword(payload)

      if (res.status) {
        dispatch(notificationSuccess({
          show: true,
          type: 'success',
          message: res.message || 'Success',
        }));
        handelLogout();
      } else {
        dispatch(notificationSuccess({
          show: true,
          type: 'error',
          message: res.message || 'Something went wrong. Please try again later.',
        }));
      }
    },
    validationSchema: changePasswordValidationSchema,
  });


  const handleChange = (e) => {
    const file1 = e.target.files[0];
    if (file1) {
      const reader = new FileReader();
      reader.onload = () => {
        setfile1({ image: reader.result });
      };
      reader.readAsDataURL(file1);
    }
  };

  const getProfileData = async () => {
    const res = await AdminDetail();
    if (res?.status) {
      setProfile(res?.data);
    } else {
      setProfile({});
    }
  }
  const handleClick = () => {
    setEdit(!edit);
  };

  useEffect(() => {
    getProfileData();
  }, []);

  console.log("dsdsdsdsdsdsd", profile);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setProfile({
      ...profile,
      [name]: value,
    });
  };
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  return (
    <>
      <Stack
        flexDirection={"row"}
        justifyContent={"flex-start"}
        gap={1}
        alignItems={"center"}
        mb={2}
      >
        <BackButton />
        <Heading head={"ProfileSettings"} />
      </Stack>
      <Grid container spacing={2} justifyContent={"center"}>
        <Grid item xs={12} md={6}>
          <Box className="card_container">
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <Grid container sx={{ width: { xs: "100%", sm: "100%" } }}>
                  <Grid item xs={12} display={"flex"} justifyContent={"center"}>
                    {!edit ? (
                      <Box
                        sx={{
                          width: "110px",
                          height: "110px",
                          background: "#f1f1f1",
                          borderRadius: "50%",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        {file1 ? (
                          <img
                            src={file1.image}
                            width={110}
                            height={110}
                            alt=""
                            style={{ borderRadius: "50%" }}
                          />
                        ) : (
                          <FaRegUser
                            style={{ fontSize: "50px", color: "#00000091" }}
                          />
                        )}
                      </Box>
                    ) : (
                      <Box
                        sx={{
                          width: "110px",
                          height: "110px",
                          background: "#f1f1f1",
                          borderRadius: "50%",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          position: "relative",
                        }}
                      >
                        {file1 ? (
                          <img
                            src={file1.image}
                            width={110}
                            height={110}
                            alt=""
                            style={{ borderRadius: "50%" }}
                          />
                        ) : (
                          <FaRegUser
                            style={{ fontSize: "50px", color: "#00000091" }}
                          />
                        )}
                        <Box
                          sx={{
                            position: "absolute",
                            cursor: "pointer",
                            bottom: "14px",
                            right: "0px",
                            borderRadius: "50%",
                          }}
                        >
                          <Box
                            sx={{
                              position: "relative",
                              width: "22px",
                              height: "22px",
                            }}
                          >
                            <MdOutlineModeEditOutline
                              style={{
                                position: "relative",
                                cursor: "pointer",
                                backgroundColor: "#4385F5",
                                color: "#fff",
                                fontSize: "22px",
                                padding: "4px",
                                borderRadius: "50%",
                              }}
                            />
                            <input
                              type="file1"
                              onChange={handleChange}
                              style={{
                                position: "absolute",
                                opacity: "0",
                                width: "100%",
                                height: "100%",
                                cursor: "pointer",
                                right: "0",
                              }}
                            />
                          </Box>
                        </Box>
                      </Box>
                    )}
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    display={"flex"}
                    justifyContent={"center"}
                    marginTop={1}
                  >
                    <Typography fontSize={"18px"} fontWeight={500}>
                      {profile.firstName} {profile.lastName}
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12} sx={{ my: 2 }}>
                <Grid container spacing={2}>
                  <Grid item xs={6}>
                    <Input
                      labelinput={"First Name"}
                      variant="standard"
                      name="firstName"
                      value={profile.firstName}
                      onChange={handleInputChange}
                      disabled={!edit}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <Input
                      labelinput={"Last Name"}
                      variant="standard"
                      name="lastName"
                      value={profile.lastName}
                      onChange={handleInputChange}
                      disabled={!edit}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <Input
                      labelinput={"Email"}
                      variant="standard"
                      name="email"
                      value={profile.email}
                      disabled
                    />
                  </Grid>
                  {user?.role != 1 && (<>
                    <Grid item xs={6}>
                      <Input
                        labelinput={"Phone"}
                        variant="standard"
                        name="phone"
                        value={profile.phone}
                        onChange={handleInputChange}
                        disabled={!edit}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <Input
                        labelinput={"Address Line 1"}
                        variant="standard"
                        name="address1"
                        value={profile.address1}
                        onChange={handleInputChange}
                        disabled={!edit}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <Input
                        labelinput={"Address Line 2"}
                        variant="standard"
                        name="address2"
                        value={profile.address2}
                        onChange={handleInputChange}
                        disabled={!edit}
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <Input
                        labelinput={"State"}
                        variant="standard"
                        name="state"
                        value={profile.state}
                        onChange={handleInputChange}
                        disabled={!edit}
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <Input
                        labelinput={"City"}
                        variant="standard"
                        name="city"
                        value={profile.city}
                        onChange={handleInputChange}
                        disabled={!edit}
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <Input
                        labelinput={"ZipCode"}
                        variant="standard"
                        name="zipCode"
                        value={profile.zipCode}
                        onChange={handleInputChange}
                        disabled={!edit}
                      />
                    </Grid></>)}

                </Grid>
              </Grid>
              <Grid item xs={12}>
                <>
                  {edit ? (
                    <>
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "center",
                        }}
                      >
                        <Button
                          buttonName={"Update Password"}
                          color="secondary"
                          onClick={() => {
                            setIsModalOpen({ open: true, currentComponent: "changePassword1", head: "Logout", para: "", handelClick: { handelLogout } })
                          }}
                        />
                        <Box
                          sx={{
                            display: "flex",
                            alignItems: "center",
                            gap: "8px",
                          }}
                        >
                          <Button
                            buttonName={"Cancel"}
                            color="tertiary"
                            onClick={handleClick}
                          />
                          <Button
                            buttonName={"Save"}
                            color="primary"
                            onClick={() => setEdit(false)}
                          />
                        </Box>
                      </Box>
                    </>
                  ) : (
                    <Box display={"flex"} justifyContent={"end"}>
                      <Button
                        buttonName={"Edit"}
                        onClick={handleClick}
                        color="primary"
                      />
                    </Box>
                  )}
                </>
              </Grid>
            </Grid>
          </Box>
        </Grid>
      </Grid>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Heading smallHead={"Update Password"} />

            <IconButton
              aria-label="close"
              onClick={handleClose}
              sx={{
                color: (theme) => theme.palette.grey[500],
              }}
            >
              <CloseIcon />
            </IconButton>
          </Box>

          <Box sx={{ display: "flex", flexDirection: "column" }}>
            <Typography>New Password</Typography>
            <Input type="text" fullWidth />
          </Box>
          <Box
            style={{
              display: "flex",
              justifyContent: "end",
              marginTop: "10px",
            }}
          >
            <Button buttonName={"Save"} color="primary" />
          </Box>
        </Box>
      </Modal>
      <Modals
        modalOpen={isModalOpen}
        setIsModalOpen={setIsModalOpen}
        handleClose={() => {
          setIsModalOpen({ open: false, currentComponent: "", para: '', head: '' })
          formik.resetForm();
        }} handelClick={handelLogout}
        formik={formik}

      />
    </>
  );
};

export default UserProfile;
