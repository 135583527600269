import { Box, Stack, Typography } from "@mui/material";
import React from "react";
import { BackButton, Heading } from "../../../components/Common";
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper } from '@mui/material';

const Participating_Agencies = () => {
  return (
    <>
      <Stack gap={2}>
        <Stack>
          <Box display={"flex"} gap={1}>
            <BackButton />
            <Heading head={"Participating Agencies"} />
          </Box>
        </Stack>
       
      </Stack>
    </>
  );
};

export default Participating_Agencies;
