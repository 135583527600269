import { useState } from "react";
import {
  Select,
  MenuItem,
  Card,
  CardContent,
  CardHeader,
  Typography,
  Badge,
  FormControl,
  Box,
  CardActions,
  Stack,
  InputAdornment,
  TextField,
  Autocomplete,
} from "@mui/material";
import { MdCalendarToday, MdSearch } from "react-icons/md";
import { BiMapPin } from "react-icons/bi";
import { Button, Heading, Modal } from "../../../components/Common";

const mockServices = [
  {
    id: 1,
    agencyName: "Helping Hands",
    serviceType: "ABA Therapy",
    location: "New York",
    state: "Texas",
    availability: "Available",
  },
  {
    id: 2,
    agencyName: "Care Plus",
    serviceType: "OT Services",
    location: "Los Angeles",
    availability: "Limited",
    state: "California",
  },
  {
    id: 3,
    agencyName: "Wellness Center",
    serviceType: "Evaluation",
    location: "Chicago",
    availability: "Unavailable",
    state: "Illinois",
  },
  {
    id: 4,
    agencyName: "Family First",
    serviceType: "Social Skills",
    location: "Houston",
    availability: "Available",
    state: "Texas",
  },
  {
    id: 5,
    agencyName: "Bright Futures",
    serviceType: "SLP Services",
    location: "Phoenix",
    availability: "Limited",
    state: "Florida",
  },
];
const countries = [
  { pincode: "10001" },
  { pincode: "25062" },
  { pincode: "10003" },
  { pincode: "30268" },
  { pincode: "90658" },
  { pincode: "63648" },
  { pincode: "10507" },
  { pincode: "10508" },
];

export default function Search_for_Services() {
  const [searchTerm, setSearchTerm] = useState("");
  const [locationFilter, setLocationFilter] = useState([]);
  const [stateFilter, setStateFilter] = useState([]);
  const [serviceTypeFilter, setServiceTypeFilter] = useState([]);
  const [availabilityFilter, setAvailabilityFilter] = useState([]);
  const [zipcodeFilter, setZipcodeFilter] = useState([]);

  const [isModalOpen, setIsModalOpen] = useState({
    open: false,
    currentComponent: "",
  });

  const filteredServices = mockServices.filter(
    (service) =>
      (service.agencyName.toLowerCase().includes(searchTerm.toLowerCase()) ||
        service.serviceType.toLowerCase().includes(searchTerm.toLowerCase())) &&
      (locationFilter.length === 0 ||
        locationFilter.includes(service.location)) &&
      (stateFilter.length === 0 || stateFilter.includes(service.state)) &&
      (serviceTypeFilter.length === 0 ||
        serviceTypeFilter.includes(service.serviceType)) &&
      (availabilityFilter.length === 0 ||
        availabilityFilter.includes(service.availability))
  );

  const uniqueLocations = Array.from(
    new Set(mockServices.map((s) => s.location))
  );
  const uniqueStates = Array.from(new Set(mockServices.map((s) => s.state)));
  const uniqueServiceTypes = Array.from(
    new Set(mockServices.map((s) => s.serviceType))
  );
  const uniqueAvailabilities = Array.from(
    new Set(mockServices.map((s) => s.availability))
  );

  const resetFilters = () => {
    setSearchTerm("");
    setLocationFilter([]);
    setStateFilter([]);
    setServiceTypeFilter([]);
    setAvailabilityFilter([]);
    setZipcodeFilter([]);
  };

  return (
    <>
      <Stack gap={2}>
        <Heading head={"Search for Services"} />
        <Box display="flex" gap="16px" alignItems="center" flexWrap="wrap" flexDirection={"row"}>
          <FormControl sx={{ width: { xs: "100%", md: "350px" } }}>
            <Typography>Search</Typography>
            <TextField
              placeholder="Search Service"
              variant="outlined"
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <MdSearch />
                  </InputAdornment>
                ),
              }}
            />
          </FormControl>
          <Box display={'flex'} flexDirection={'row'} flexWrap={'wrap'} alignItems={'end'} gap={1}>
            
            {/* City Autocomplete */}
            <FormControl style={{ minWidth: 250 }}>
              <Typography>City</Typography>
              <Autocomplete
                multiple
                options={uniqueLocations}
                value={locationFilter}
                onChange={(event, newValue) => setLocationFilter(newValue)}
                renderInput={(params) => <TextField {...params} />}
              />
            </FormControl>
            {/* State Autocomplete */}
            <FormControl style={{ minWidth: 250 }}>
              <Typography>State</Typography>
              <Autocomplete
                multiple
                options={uniqueStates}
                value={stateFilter}
                onChange={(event, newValue) => setStateFilter(newValue)}
                renderInput={(params) => <TextField {...params} />}
              />
            </FormControl>
            {/* ZipCode Autocomplete (unchanged) */}
            <FormControl style={{ minWidth: 250 }}>
              <Typography>ZipCode</Typography>
              <Autocomplete
                multiple
                id="zipcode-autocomplete"
                options={countries}
                getOptionLabel={(option) => option.pincode}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    InputProps={{
                      ...params.InputProps,
                      autoComplete: "zipcode", // disable autocomplete and autofill
                    }}
                  />
                )}
                onChange={(event, newValue) => {
                  setZipcodeFilter(newValue.map((option) => option.pincode));
                }}
              />
            </FormControl>
            {/* Service Type Autocomplete */}
            <FormControl style={{ minWidth: 250 }}>
              <Typography>Service Type</Typography>
              <Autocomplete
                multiple
                options={uniqueServiceTypes}
                value={serviceTypeFilter}
                onChange={(event, newValue) => setServiceTypeFilter(newValue)}
                renderInput={(params) => <TextField {...params} />}
              />
            </FormControl>
            {/* Availability Autocomplete */}
            <FormControl style={{ minWidth: 250 }}>
              <Typography>Availability</Typography>
              <Autocomplete
                multiple
                options={uniqueAvailabilities}
                value={availabilityFilter}
                onChange={(event, newValue) => setAvailabilityFilter(newValue)}
                renderInput={(params) => <TextField {...params} />}
              />
            </FormControl>

            <Button
              variant="contained"
              color="primary"
              onClick={resetFilters}
              buttonName={"Clear All"}
              
            />
          </Box>
        </Box>

        <Stack>
          <Heading smallHead={"Available Location"} />
          <div
            style={{
              display: "grid",
              gridTemplateColumns: "repeat(auto-fill, minmax(300px, 1fr))",
              gap: "16px",
            }}
          >
            {filteredServices.map((service) => (
              <Card key={service.id}>
                <CardHeader
                  title={service.agencyName}
                  subheader={service.serviceType}
                />
                <CardContent>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      marginBottom: "8px",
                    }}
                  >
                    <BiMapPin style={{ marginRight: "8px" }} />
                    <Typography>{service.location}</Typography>
                  </div>
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <MdCalendarToday style={{ marginRight: "8px" }} />
                    <Badge
                      sx={{
                        color:
                          service.availability === "Available"
                            ? "#34A853"
                            : service.availability === "Limited"
                            ? "#FABC05"
                            : "#EA4235",
                        fontWeight: 700,
                      }}
                    >
                      {service.availability}
                    </Badge>
                  </div>
                </CardContent>
                <CardActions>
                  <Button
                    variant="contained"
                    color="primary"
                    buttonName={"View Details"}
                    onClick={() => {
                      setIsModalOpen({
                        open: true,
                        currentComponent: "agencydetails",
                      });
                    }}
                  />
                </CardActions>
              </Card>
            ))}
          </div>
        </Stack>

        {filteredServices.length === 0 && (
          <Typography
            align="center"
            color="textSecondary"
            style={{ marginTop: "16px" }}
          >
            No services found matching your criteria.
          </Typography>
        )}
      </Stack>
      <Modal
        modalOpen={isModalOpen}
        handleClose={() =>
          setIsModalOpen({ open: false, currentComponent: "" })
        }
      />
    </>
  );
}
