import { deleteRequest, get, post, put } from "../web.request";

export const userDetails = async (id) => {
  return await get(`/userDetail/${id}`);
}

export const userAdd = async (data) => {
  return await post(`/createUser`, data);
}

export const userList = async (query) => {
  return await get(`/userList${query}`);
}

export const userDelete = async (id) => {
  return await deleteRequest(`/userDelete/${id}`);
}

export const userUpdate = async (id, data) => {
  return await put(`/userUpdate/${id}`, data);
}

export const userStatusChange = async (id) => {
  return await put(`/userStatusUpdate/${id}`);
}

//userRegister
export const userRegister = async (data) => {
  return await post(`/userRegister`, data);
}