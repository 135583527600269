import React, { useEffect, useState } from 'react';
import { Box, Stack, Tooltip, Typography } from '@mui/material';
import { AiOutlineEdit, CgMenuLeft, FaUserLarge, GoBell, IoEyeOutline, PiBellSimpleRinging } from '../../helper/Icons';
import { Link, useLocation } from 'react-router-dom';
import { MenuButton, Modal } from '../Common';
import { useNavigate } from 'react-router-dom';
import * as DropdownMenu from "@radix-ui/react-dropdown-menu";
import { IoIosArrowDown } from "react-icons/io";
import { MdOutlineLogout, MdOutlineManageAccounts, MdPassword } from 'react-icons/md';
import { logoutApi } from '../../state/Action/authAction';
import { useDispatch, useSelector } from 'react-redux';
import { notificationSuccess } from '../../state/Action/NotificationAction';
import { changePassword } from '../../Service/auth.service';
import { changePasswordValidationSchema } from '../../helper/validation';
import { changePasswordInitialValues } from '../../helper/initialValues';
import { useFormik } from 'formik';

const Header = ({ handleDrawerToggle, handleDrawerCloseToggleWeb }) => {
  const location = useLocation();
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state?.root?.auth);
  console.log("userfdfdfdfdfdf", user);
  const Navigate = useNavigate();
  const [isModalOpen, setIsModalOpen] = useState({
    open: false,
    currentComponent: "",
    para: '', head: ''
  });
  const [isAlertOpen, setIsAlertOpen] = useState(false);
  const handleAlertClick = () => { setIsAlertOpen(true) };
  const handelLogout = () => {
    dispatch(logoutApi());
    localStorage.removeItem('token');
    Navigate('/');
  }

  const formik = useFormik({
    initialValues: changePasswordInitialValues,
    onSubmit: async (value) => {

      if (value.newPassword !== value.confirmPassword) {
        dispatch(notificationSuccess({
          show: true,
          type: 'error',
          message: 'Password and confirm password does not match.',
        }));
        return;
      }

      const payload = {
        oldPassword: value.oldPassword,
        newPassword: value.newPassword,
      }

      const res = await changePassword(payload)

      if (res.status) {
        dispatch(notificationSuccess({
          show: true,
          type: 'success',
          message: res.message || 'Success',
        }));
        handelLogout();
      } else {
        dispatch(notificationSuccess({
          show: true,
          type: 'error',
          message: res.message || 'Something went wrong. Please try again later.',
        }));
      }
    },
    validationSchema: changePasswordValidationSchema,
  });


  return (
    <>
      <Stack flexDirection={'row'} justifyContent={'space-between'} width={'100%'} alignItems={'center'}>
        <Stack flexDirection={'row'} alignItems={'center'} gap={{ xs: '6px', sm: 1 }} justifyContent={'flex-start'}>
          <Box sx={{ display: { xs: 'flex', md: 'none' } }}>
            <MenuButton icon={<CgMenuLeft style={{ color: '#000', fontSize: '18px' }} onClick={handleDrawerToggle} />} />
          </Box>
          {/* <Tooltip title='collapse'> */}
          <Box sx={{ display: { xs: 'none', md: 'block' } }}>
            <MenuButton icon={<CgMenuLeft style={{ color: '#000', fontSize: '18px' }} onClick={handleDrawerCloseToggleWeb} />} />
          </Box>
          {/* </Tooltip> */}
          {/* <Search /> */}

        </Stack>
        <Box>
          <Stack flexDirection={'row'} gap={{ xs: '6px', sm: 1 }} justifyContent={'center'} alignItems={'center'}>
            <DropdownMenu.Root open={isAlertOpen} onOpenChange={(open) => setIsAlertOpen(open)}>
              <Tooltip title="Notification">
                <DropdownMenu.Trigger className='notificationDropDownTrigger'>
                  <MenuButton icon={<GoBell style={{ color: '#000', fontSize: '18px' }} />} />
                </DropdownMenu.Trigger>
              </Tooltip>
              <DropdownMenu.Content className='notificationDropDownContent' align='end' onClick={handleAlertClick}>
                <DropdownMenu.Item className='notificationDropDownMenu' onClick={handleAlertClick}>
                  <Box sx={{ width: '350px', height: '300px' }}>
                    <Stack flexDirection={'row'} justifyContent={'space-between'} alignItems={'center'} pb={'10px'}>
                      <Typography fontSize={'13px'} fontWeight={700}>{"All_Notification"}</Typography>
                      <Typography fontSize={'11px'} fontWeight={400}><Link>{"Mark_all_as_read"}</Link></Typography>
                    </Stack>
                    <Box maxHeight={'calc(100% - 30px)'} height={'100%'} sx={{ overflow: 'hidden' }}>
                      <Stack gap={1} sx={{ height: '-webkit-fill-available', overflow: 'scroll' }}>
                        {[1, 1, 1, 1, 1].map((data, i) => {
                          return (
                            <Stack key={i} flexDirection={'row'} gap={1} alignItems={'center'} sx={{ background: '#4385f512', padding: 1 }}>
                              <Box>
                                <PiBellSimpleRinging style={{ fontSize: '22px' }} />
                              </Box>
                              <Box>
                                <Stack flexDirection={'row'} justifyContent={'space-between'} alignItems={'center'} pb={'2px'}>
                                  <Typography fontSize={'12px'} fontWeight={600}>New email update!</Typography>
                                  <Typography fontSize={'10px'} fontWeight={400}>3 min ago</Typography>
                                </Stack>
                                <Typography fontSize={'10px'} fontWeight={400} color={'#a1a1a1'}>Now, anyone receiving your ticketing emails will see a simplified return address see...</Typography>
                              </Box>
                            </Stack>
                          )
                        })}
                      </Stack>
                    </Box>
                  </Box>
                </DropdownMenu.Item>
              </DropdownMenu.Content>
            </DropdownMenu.Root>
            <DropdownMenu.Root>
              <DropdownMenu.Trigger className='chatDropDown'>
                <Stack flexDirection={'row'} gap={1} justifyContent={'center'} alignItems={'center'} sx={{ border: '1px solid rgba(0, 0, 0, 0.12)', background: '#fff', p: '8px 10px', borderRadius: '6px' }}>
                  <FaUserLarge style={{ borderRadius: '50%', color: '#4385F5', fontSize: '18px' }} />
                  <Stack sx={{ display: { xs: 'none', sm: 'block' } }}>
                    <Typography fontSize="14px" lineHeight={1.4}>
                      {(() => {
                        if (user?.role === 1) {
                          return `${user.firstName} ${user.lastName}`;
                        } else if (user?.role === 2) {
                          return user?.agencyName;
                        } else {
                          return `${user.firstName} ${user.lastName}`;
                        }
                      })()}
                    </Typography>
                  </Stack>

                  <IoIosArrowDown sx={{ fontSize: '10px' }} />
                </Stack>
              </DropdownMenu.Trigger>
              <DropdownMenu.Content className='chatDropDownContent' align='end'>
                <DropdownMenu.Item className='chatDropDownMenu' onClick={() => {
                  Navigate(user?.role === 2 ? '/agency-profile' : user?.role === 1 ? '/profile' : '/user-profile')
                }}>
                  <Stack flexDirection={'row'} gap={1} alignItems={'center'}><MdOutlineManageAccounts style={{ fontSize: '15px' }} /><Box sx={{ fontSize: '13px' }}>{"Profile Settings"}</Box></Stack>
                </DropdownMenu.Item>
                <DropdownMenu.Item className='chatDropDownMenu' onClick={() => {
                  setIsModalOpen({ open: true, currentComponent: "changePassword1", head: "Logout", para: "", handelClick: { handelLogout } })

                }} >
                  <Stack flexDirection={'row'} gap={1} alignItems={'center'}><MdPassword style={{ fontSize: '15px' }} /><Box sx={{ fontSize: '13px' }}>{"Change Password"}</Box></Stack>
                </DropdownMenu.Item>
                <DropdownMenu.Item className='chatDropDownMenu' onClick={() => {
                  setIsModalOpen({ open: true, currentComponent: "delete", head: "Logout", para: "Are you sure you want to logout", handelClick: { handelLogout } })
                }}>
                  <Stack flexDirection={'row'} gap={1} alignItems={'center'}><MdOutlineLogout style={{ fontSize: '15px' }} /><Box sx={{ fontSize: '13px' }}>{"Logout"}</Box></Stack>
                </DropdownMenu.Item>
              </DropdownMenu.Content>
            </DropdownMenu.Root>
          </Stack>
        </Box>
      </Stack>
      <Modal
        modalOpen={isModalOpen}
        setIsModalOpen={setIsModalOpen}
        handleClose={() => {
          setIsModalOpen({ open: false, currentComponent: "", para: '', head: '' })
          formik.resetForm();
        }} handelClick={handelLogout}
        formik={formik}

      />
    </>
  )
}

export default Header