import React from "react";
import {
  Container,
  Grid,
  Typography,
  Paper,
  Box,
  Card,
  CardContent,
  CardHeader,
  Divider,
  IconButton,
  Tooltip,
} from "@mui/material";
import { BackButton, Button, Heading } from "../../components/Common";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import DateRangeIcon from "@mui/icons-material/DateRange";
import WatchLaterIcon from "@mui/icons-material/WatchLater";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import PhoneIcon from "@mui/icons-material/Phone";
import EmailIcon from "@mui/icons-material/Email";

const Waitlist_Details = () => {
  const dummyData = {
    serviceName: "ABA Service",
    dateInit: "2024-09-12",
    estWaitTime: "15 minutes",
    child: {
      name: "John Doe",
      dob: "2016-05-10",
    },
    address: {
      line1: "123 Main St",
      line2: "Apt 4B",
      city: "New York",
      state: "NY",
      zipcode: "10001",
    },
    insurance: {
      insuranceName: "Blue Cross Blue Shield",
      parentName: "Jane Doe",
      parentPhone: "(555) 123-4567",
      parentEmail: "jane.doe@example.com",
    },
    availability: {
      week: "Monday to Friday",
      from: "09:00 AM",
      to: "05:00 PM",
    },
  };

  return (
    <>
      <Box display={"flex"} gap={1} mb={2}>
        <BackButton />
        <Heading head={dummyData.child.name} />
      </Box>

      <Container>
        <Card elevation={4}>
          <CardHeader
            title={
              <span
                style={{
                  fontWeight: "bold",
                  fontSize: "18px",
                  paddingBottom: "10px",
                }}
                color="primary"
              >
                {dummyData.serviceName}
              </span>
            }
            subheader={
              <>
                <Typography variant="subtitle1" color="textSecondary">
                  <DateRangeIcon fontSize="small" /> Date Init:{" "}
                  {dummyData.dateInit}
                </Typography>
                <Typography variant="subtitle1" color="textSecondary">
                  <WatchLaterIcon fontSize="small" /> Estimated Wait Time:{" "}
                  {dummyData.estWaitTime}
                </Typography>
              </>
            }
          />

          <CardContent>
            {/* Child Information */}
            <Box mb={3}>
              <span className="mainh1">Child's Details</span>
              <Divider />
              <Grid container spacing={2} mt={1}>
                <Grid item xs={6}>
                  <Typography variant="body1">
                    <AccountCircleIcon fontSize="small" />{" "}
                    <strong>Name:</strong> {dummyData.child.name}
                  </Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography variant="body1">
                    <DateRangeIcon fontSize="small" /> <strong>DOB:</strong>{" "}
                    {dummyData.child.dob}
                  </Typography>
                </Grid>
              </Grid>
            </Box>
            {/* Child Information */}
            <Box mb={3}>
              <span className="mainh1">Parent's Details</span>
              <Divider />
              <Grid container spacing={2} mt={1}>
                <Grid item xs={6}>
                  <Typography variant="body1">
                    <strong>Parent's Name:</strong>{" "}
                    {dummyData.insurance.parentName}
                  </Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography variant="body1">
                    <PhoneIcon fontSize="small" /> <strong>Phone:</strong>{" "}
                    {dummyData.insurance.parentPhone}
                  </Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography variant="body1">
                    <EmailIcon fontSize="small" /> <strong>Email:</strong>{" "}
                    {dummyData.insurance.parentEmail}
                  </Typography>
                </Grid>
              </Grid>
            </Box>

            {/* Address Information */}
            <Box mb={3}>
              <span className="mainh1">Address</span>
              <Divider />
              <Typography variant="body1" mt={1}>
                <LocationOnIcon fontSize="small" /> {dummyData.address.line1},{" "}
                {dummyData.address.line2}
              </Typography>
              <Typography variant="body1">
                {dummyData.address.city}, {dummyData.address.state},{" "}
                {dummyData.address.zipcode}
              </Typography>
            </Box>

            {/* Insurance Information */}
            <Box mb={3}>
              <span className="mainh1">Insurance Information</span>
              <Divider />
              <Grid container spacing={2} mt={1}>
                <Grid item xs={6}>
                  <Typography variant="body1">
                    <strong>Insurance Name:</strong>{" "}
                    {dummyData.insurance.insuranceName}
                  </Typography>
                </Grid>
              </Grid>
            </Box>

            {/* Availability Information */}
            <Box mb={3}>
              <span className="mainh1">Availability</span>
              <Divider />
              <Grid container >
                <Grid xs={6} sm={3}>
                  <Typography variant="body1" mt={1}>
                    <strong>Day:</strong> Monday
                  </Typography>
                  <Typography variant="body1">
                    <strong>From:</strong> {dummyData.availability.from}
                  </Typography>
                  <Typography variant="body1">
                    <strong>To:</strong> {dummyData.availability.to}
                  </Typography>
                </Grid>
                <Grid xs={6} sm={3}>
                  <Typography variant="body1" mt={1}>
                    <strong>Day:</strong> Tuesday
                  </Typography>
                  <Typography variant="body1">
                    <strong>From:</strong> 3:00 PM
                  </Typography>
                  <Typography variant="body1">
                    <strong>To:</strong> 5:00 PM
                  </Typography>
                </Grid>
                <Grid xs={6} sm={3}>
                  <Typography variant="body1" mt={1}>
                    <strong>Day:</strong> Wednesday
                  </Typography>
                  <Typography variant="body1">
                    <strong>From:</strong> 9:00 AM
                  </Typography>
                  <Typography variant="body1">
                    <strong>To:</strong> 5:00 PM
                  </Typography>
                  </Grid>
                  <Grid xs={6} sm={3}>
                  <Typography variant="body1" mt={1}>
                    <strong>Day:</strong> Thrusday
                  </Typography>
                  <Typography variant="body1">
                    <strong>From:</strong> 10:00 AM
                  </Typography>
                  <Typography variant="body1">
                    <strong>To:</strong> 01:00 PM
                  </Typography>
                  </Grid>
              </Grid>
            </Box>
            <Grid item xs={12} mt={1}>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <Button buttonName={"Edit"} color="secondary" />
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    gap: "8px",
                  }}
                >
                  <Button buttonName={"Cancel"} color="tertiary" />
                  <Button buttonName={"Save"} color="primary" />
                </Box>
              </Box>
            </Grid>
          </CardContent>
        </Card>
      </Container>
    </>
  );
};

export default Waitlist_Details;
