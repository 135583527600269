import React from "react";
import { Dialog, DialogTitle, DialogContent, DialogActions, Grid } from "@mui/material";
import { Button, Input } from "../../components/Common";

const Address_Modal = ({ isDialogOpen, handleCloseDialog, address, handleInputChange }) => {
  return (
    <Dialog open={isDialogOpen} onClose={handleCloseDialog} fullWidth>
      <DialogTitle>Edit Address</DialogTitle>
      <DialogContent>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6}>
            <Input
              labelinput="First Name"
              name="firstName"
              value={address.firstName}
              onChange={handleInputChange}
              variant="outlined"
              fullWidth
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <Input
              labelinput="Last Name"
              name="lastName"
              value={address.lastName}
              onChange={handleInputChange}
              variant="outlined"
              fullWidth
            />
          </Grid>
          <Grid item xs={12}>
            <Input
              labelinput="Address Line 1"
              name="addressLine1"
              value={address.addressLine1}
              onChange={handleInputChange}
              variant="outlined"
              fullWidth
            />
          </Grid>
          <Grid item xs={12}>
            <Input
              labelinput="Address Line 2"
              name="addressLine2"
              value={address.addressLine2}
              onChange={handleInputChange}
              variant="outlined"
              fullWidth
            />
          </Grid>
          <Grid item xs={6} sm={4}>
            <Input
              labelinput="City"
              name="city"
              value={address.city}
              onChange={handleInputChange}
              variant="outlined"
              fullWidth
            />
          </Grid>
          <Grid item xs={6} sm={4}>
            <Input
              labelinput="Country"
              name="country"
              value={address.country}
              onChange={handleInputChange}
              variant="outlined"
              fullWidth
            />
          </Grid>
          <Grid item xs={6} sm={4}>
            <Input
              labelinput="Postal Code"
              name="postalCode"
              value={address.postalCode}
              onChange={handleInputChange}
              variant="outlined"
              fullWidth
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button
          variant="contained"
          onClick={handleCloseDialog}
          buttonName="Cancel"
          color="tertiary"
        />
        <Button
          variant="contained"
          onClick={handleCloseDialog}
          buttonName="Save Address"
        />
      </DialogActions>
    </Dialog>
  );
};

export default Address_Modal;
